import React, { useEffect, useState, useRef } from "react";
import appovalRate from "../images/home/appoval-rate.svg";
import paperPen from "../images/home/paper-pen.svg";
import Reviews from "../images/home/review-like.svg";
import Agent from "../images/home/agent.svg";
import watch from "../images/home/watch.svg";
import money from "../images/home/money.svg";
import fastTracked from "../images/home/fast-traced.svg";

const Partner = () => {
  const [visasGranted, setVisasGranted] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const [rate, setRate] = useState(0);

  const partnerRef = useRef(null); 

  const startCounting = () => {
    const targetRate = 95.8;
    const incrementRate = targetRate / 100;
    const rateInterval = setInterval(() => {
      setRate(prevRate => {
        if (prevRate >= targetRate) {
          clearInterval(rateInterval);
          return targetRate;
        }
        return prevRate + incrementRate;
      });
    }, 30);

    const targetVisas = 2100;
    const incrementVisas = targetVisas / 100;
    const visasInterval = setInterval(() => {
      setVisasGranted(prevCount => {
        if (prevCount >= targetVisas) {
          clearInterval(visasInterval);
          return targetVisas;
        }
        return prevCount + incrementVisas;
      });
    }, 30);

    const targetReviews = 600;
    const incrementReviews = targetReviews / 100;
    const reviewsInterval = setInterval(() => {
      setReviewsCount(prevCount => {
        if (prevCount >= targetReviews) {
          clearInterval(reviewsInterval);
          return targetReviews;
        }
        return prevCount + incrementReviews;
      });
    }, 30);

    const targetYears = 4;
    const incrementYears = targetYears / 100;
    const yearsInterval = setInterval(() => {
      setYearsOfExperience(prevCount => {
        if (prevCount >= targetYears) {
          clearInterval(yearsInterval);
          return targetYears;
        }
        return prevCount + incrementYears;
      });
    }, 30);
  };

  useEffect(() => {
    const options = {
      root: null, 
      rootMargin: "0px",
      threshold: 0.5, 
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        startCounting(); 
        observer.unobserve(partnerRef.current); 
      }
    }, options);

    if (partnerRef.current) {
      observer.observe(partnerRef.current); 
    }

    return () => {
      if (partnerRef.current) {
        observer.unobserve(partnerRef.current); 
      }
    };
  }, []);

  return (
    <div className="container max-w-1440px mx-auto px-4 lg:px-14 py-10">
      <div className="flex justify-center items-center pb-10 mt-12">
        <div className="w-2 h-2 bg-blue rotate-45">.</div>
        <div className="w-[790px] h-[2px] bg-blue"></div>
        <div className="w-2 h-2 bg-blue rotate-45">.</div>
      </div>

      <div className="max-w-[1264px] text-center">
        <div className="text-[38px] lg:text-[54px] text-blue hellix-font font-semibold">
          Why Partner with Us?
        </div>
        <div className="text-[22px] text-blue hellix-font font-normal">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>
      </div>

      <div
        ref={partnerRef} // Attach the ref to this div for visibility detection
        className="flex flex-wrap max-lg:gap-2 justify-center lg:justify-between py-4 lg:py-10"
      >
        <div className="w-[168px] h-[164px] lg:w-[295px] lg:h-[215px] bg-sky rounded-[15px] border border-[#A7COCB] flex flex-col items-center justify-center p-4">
          <div>
            <img
              src={appovalRate}
              alt="Visa Approval Rate"
              className="lg:w-auto lg:h-auto w-[74px] h-[74px]"
            />
          </div>
          <div className="text-base text-blue hellix-font font-semibold pt-3 text-center">
            {rate.toFixed(1)}% Visa <br /> Approval Rate
          </div>
        </div>

        <div className="w-[168px] h-[164px] lg:w-[295px] lg:h-[215px] bg-sky rounded-[15px] border border-[#A7COCB] flex flex-col items-center justify-center p-4">
          <div>
            <img
              src={paperPen}
              alt="paperPen"
              className="lg:w-auto lg:h-auto w-[74px] h-[74px]"
            />
          </div>
          <div className="text-base text-blue hellix-font font-semibold pt-3 text-center">
            {Math.floor(visasGranted)}+ Visas <br /> Granted
          </div>
        </div>

        <div className="w-[168px] h-[164px] lg:w-[295px] lg:h-[215px] bg-sky rounded-[15px] border border-[#A7COCB] flex flex-col items-center justify-center p-4">
          <div>
            <img
              src={Agent}
              alt="Migration Agent"
              className="lg:w-auto lg:h-auto w-[74px] h-[74px]"
            />
          </div>
          <div className="text-base text-blue hellix-font font-semibold pt-3 text-center">
            Registered <br /> Migration Agents
          </div>
        </div>

        <div className="w-[168px] h-[164px] lg:w-[295px] lg:h-[215px] bg-sky rounded-[15px] border border-[#A7COCB] flex flex-col items-center justify-center p-4">
          <div>
            <img
              src={Reviews}
              alt="Reviews Like"
              className="lg:w-auto lg:h-auto w-[74px] h-[74px]"
            />
          </div>
          <div className="text-base text-blue hellix-font font-semibold pt-3 text-center">
            {Math.floor(reviewsCount)}+ Positive <br /> Reviews
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-center gap-3 lg:gap-10">
        <div className="w-[168px] h-[164px] lg:w-[295px] lg:h-[215px] bg-sky rounded-[15px] border border-[#A7COCB] flex flex-col items-center justify-center p-4">
          <div>
            <img
              src={watch}
              alt="watch"
              className="lg:w-auto lg:h-auto w-[74px] h-[74px]"
            />
          </div>
          <div className="text-base text-blue hellix-font font-semibold pt-3 text-center">
            {Math.floor(yearsOfExperience)}+ Years of <br /> Experience
          </div>
        </div>

        <div className="w-[168px] h-[164px] lg:w-[295px] lg:h-[215px] bg-sky rounded-[15px] border border-[#A7COCB] flex flex-col items-center justify-center p-4">
          <div>
            <img
              src={money}
              alt="Money Dollar"
              className="lg:w-auto lg:h-auto w-[74px] h-[74px]"
            />
          </div>
          <div className="text-base text-blue hellix-font font-semibold pt-3 text-center">
            Competitive <br /> Processing <br /> charges
          </div>
        </div>

        <div className="w-[168px] lg:w-[295px] lg:h-[215px] bg-sky rounded-[15px] border border-[#A7COCB] flex flex-col items-center justify-center p-2 lg:p-4">
          <div>
            <img
              src={fastTracked}
              alt="FAST-TRACKED"
              className="lg:w-auto lg:h-auto w-[74px] h-[74px]"
            />
          </div>
          <div className="text-base text-blue hellix-font font-semibold pt-3 text-center">
            FAST-TRACKED PROCESS <br /> AND COMMUNICATION FOR <br /> EFFECTIVE OUTCOMES.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
