import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import careerAdvancement from "../../images/employee/carrer-adverstiment.svg";
import likeHome from "../../images/employee/material-symbols_family-home-outline.svg";
import meetingCorparte from "../../images/individual/MediumTerm.jpg";
import labourAgreement from "../../images/individual/LabourAgreement.jpg";
import toggleIcon from "../../images/individual/fa-solid_stream.svg";
import contactSign from "../../images/individual/VisaStreams.jpg";
import occupationalMedicine from "../../images/individual/TSS.jpg";
import rightVector from "../../images/individual/right-vector.svg";
import immigetGlobal from "../../images/individual/immiget-global.png";
import applicationProcess from "../../images/individual/application-proccess.svg";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";

const EmployeeTemporarySkilledVisa = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <div className="w-full h-90 font-Hellix bg-cover bg-center bg-image">
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Temporary Skill Shortage (TSS) Visa (Subclass 482) 
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Temporary Skill Shortage (TSS) Visa (Subclass 482) 
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Temporary Skill Shortage (TSS) 
Visa (Subclass 482) */}
      <div className="xl:flex gap-8 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            Temporary Skill Shortage (TSS) Visa (Subclass 482) 
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Temporary Skill Shortage (TSS) Visa (Subclass 482) is a
            temporary visa designed to address labor shortages in Australia. It
            enables employers to sponsor skilled workers from overseas to fill
            critical gaps in their workforce. 
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={occupationalMedicine} alt="banner-image" />
        </div>
      </div>

      {/* Visa Streams  */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={toggleIcon} alt="toggle Icon" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Visa Streams
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] my-2 hellix-font">
          The Employer Sponsor Nomination Visa offers multiple pathways designed
          to cater to various skill levels and business needs. These include:
        </div>
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[50%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              1. Short-Term Stream
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Validity: Up to 2 years (or 4 years in exceptional
                  circumstances) 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Occupation List: Short-Term Skilled Occupation List (STSOL) 
                </li>
              </ul>
            </div>
            <div className="text-blue font-medium text-xl lg:text-[24px] my-2 hellix-font">
              Eligibility: 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Skilled workers with specialized skills 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Employer must demonstrate a genuine need for the position 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Labour Market Testing (LMT) required 
                </li>
              </ul>
            </div>
            <div className="text-blue font-medium text-xl lg:text-[24px] my-2 hellix-font">
              Benefits: 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Temporary residence in Australia 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Work for the sponsoring employer 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Pathway to permanent residence (in exceptional circumstances) 
                </li>
              </ul>
            </div>
          </div>

          <div className="xl:w-[50%]">
            <img src={contactSign} alt="Contact Sign" />
          </div>
        </div>

        <div className="xl:flex gap-4 justify-center mt-12">
          <div className="xl:w-[40%]">
            <img src={meetingCorparte} alt="Meeting Corparte" />
          </div>
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              2. Medium-Term Stream  
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Validity: Up to 4 years 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Occupation List: Medium and Long-Term Strategic Skills List
                  (MLTSSL) 
                </li>
              </ul>
            </div>
            <div className="text-blue font-medium text-xl lg:text-[24px] my-2 hellix-font">
              Eligibility: 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Skilled workers with critical skills 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Employer must demonstrate a genuine need for the position 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Labour Market Testing (LMT) required 
                </li>
              </ul>
            </div>
            <div className="text-blue font-medium text-xl lg:text-[24px] my-2 hellix-font">
              Benefits: 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Temporary residence in Australia 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Work for the sponsoring employer 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Pathway to permanent residence 
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="xl:flex gap-4 justify-center mt-12">
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              3. Labour Agreement Stream  
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Validity: Varies (typically up to 4 years) 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Occupation List: As specified in the labour agreement 
                </li>
              </ul>
            </div>
            <div className="text-blue font-medium text-xl lg:text-[24px] my-2 hellix-font">
              Eligibility: 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Skilled workers sponsored by employers under a labour
                  agreement 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Employer must demonstrate a genuine need for the position 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Labour agreement must be approved by the Australian
                  Government 
                </li>
              </ul>
            </div>
            <div className="text-blue font-medium text-xl lg:text-[24px] my-2 hellix-font">
              Benefits: 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Temporary residence in Australia 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Work for the sponsoring employer 
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  Pathway to permanent residence (in exceptional circumstances) 
                </li>
              </ul>
            </div>
          </div>

          <div className="xl:w-[40%]">
            <img src={labourAgreement} alt="Labour Agreement" />
          </div>
        </div>
        <div className="text-blue font-normal text-xl lg:text-[22px] pt-3 hellix-font">
          These streams offer flexibility for employers to sponsor skilled
          workers, addressing specific labor market needs. 
        </div>
      </div>

      {/*Key Features  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Key Features
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  p-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Temporary visa with a validity period of up to 4 years 
            </div>
          </div>
          <div className="shadow-custom-white  p-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={careerAdvancement} alt="Career-Advancement" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Allows skilled workers to work in Australia for an approved
              sponsor 
            </div>
          </div>
          <div className="shadow-custom-white p-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={likeHome} alt="Like Home" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              Pathway to permanent residence through the Permanent Residence
              (Skilled Regional) Visa (Subclass 191) 
            </div>
          </div>
        </div>
        <div className="flex gap-10 justify-center mt-8  ">
          <div className="shadow-custom-white  p-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Requires a nomination from an approved sponsor 
            </div>
          </div>
          <div className="shadow-custom-white  p-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Points-tested visa with a minimum of 65 points required 
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Requirements for the Subclass 600 Visa   */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Eligibility
          </div>
        </div>
        <div className="lg:text-[22px] text-lg  text-blue font-normal hellix-font py-2">
          The Employer Sponsor Nomination Visa has requirements for both
          sponsoring employers and nominated workers:
        </div>

        <div className="lg:ml-4 mt-2">
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Age:
              </span>
              Under 45 years{" "}
            </div>
          </div>
          <div className="flex gap-2 my-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                English language proficiency: 
              </span>
              Competent English (IELTS/TOEFL/PTE/OET){" "}
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Qualifications and experience:{" "}
              </span>
              Relevant to occupation (skills assessment required) 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Health and character:  {" "}
              </span>
              Meet standard health and character requirements 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Debt and compliance:{" "}
              </span>
              No outstanding debts to the Australian Government 
            </div>
          </div>
        </div>

        <div className="lg:text-[25px] text-lg  text-blue font-semibold hellix-font py-2">
          Sponsorship Requirements: 
        </div>
        <div className="lg:ml-4 mt-2">
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Employer must be an approved sponsor 
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Labour Market Testing (LMT) required 
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Sponsor must demonstrate a genuine need for the position 
            </div>
          </div>
        </div>

        <div className="lg:text-[25px] text-lg  text-blue font-semibold hellix-font py-2">
          Occupation Lists:
        </div>
        <div className="lg:ml-4 mt-2">
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
               Medium and Long-Term Strategic Skills List (MLTSSL) 
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Short-Term Skilled Occupation List (STSOL) 
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Regional Occupation List (ROL) 
            </div>
          </div>
        </div>
        <div className="lg:text-[25px] text-lg  text-blue font-semibold hellix-font py-2">
          Pathway to Permanent Residence: 
        </div>
        <div className="lg:ml-4 mt-2">
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
               Meet eligibility requirements for the Subclass 191 visa 
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Work for the sponsoring employer for at least 3 years 
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Meet income and taxation requirements 
            </div>
          </div>
        </div>
      </div>

      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Benefits
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  p-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Live and work in Australia for up to 4 years 
            </div>
          </div>
          <div className="shadow-custom-white  p-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={careerAdvancement} alt="Career-Advancement" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Pathway to permanent residence 
            </div>
          </div>
          <div className="shadow-custom-white p-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={likeHome} alt="Like Home" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              Access to Medicare and healthcare services 
            </div>
          </div>

          <div className="shadow-custom-white  p-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Eligibility for skilled graduate visas for family members 
            </div>
          </div>
        </div>
      </div>

      {/* Application Process   */}
      <div className=" container max-w-1440px  mx-auto px-4  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={applicationProcess} alt=" Application Process" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Application Process
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
            Step 1: Sponsorship Application:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            The employer submits a sponsorship application to become an approved
            sponsor, including demonstrating their need for overseas workers and
            commitment to hiring locally.
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 2: Nomination Application:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            Once the sponsorship is approved, the employer nominates a specific
            position for sponsorship, providing evidence that the role meets
            labor market needs and aligns with immigration standards.
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 3: Visa Application:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            {" "}
            The nominated individual applies for the visa, submitting personal
            documents, proof of skills and experience, and meeting health and
            character requirements.
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 4: Decision and Granting of Visa:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            The Department of Home Affairs assesses the application. Upon
            approval, the applicant can begin working in Australia as per the
            conditions of the visa.
          </div>
        </div>
      </div>

      {/* How Immiget Global Can Assist with Your Subclass 600 Application */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Will Help You?
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Immiget Global is here to assist at every stage, from sponsorship
          applications to final approvals. Our team provides:
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img
              src={immigetGlobal}
              alt="Immiget Global"
              className="h-[100%]"
            />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-4">
            <ul className="list-inside">
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold py-3">
                <span>&bull;</span>
                <div>
                  Guidance on Eligibility and Requirements:{" "}
                  <span className="font-normal">
                    We assess both employer and applicant eligibility, ensuring
                    every requirement is met before application.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Labor Market Testing Support:
                  <span className="font-normal">
                    We offer expertise in preparing and documenting labor market
                    testing requirements to satisfy government criteria.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold py-2">
                <span>&bull;</span>
                <div>
                  Tailored Advice on Visa Pathways:
                  <span className="font-normal">
                    Whether temporary or permanent residency is your goal, we
                    help align the best visa pathway to meet long-term
                    aspirations.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Comprehensive Application Management:
                  <span className="font-normal">
                    We handle the paperwork, liaise with the Department of Home
                    Affairs, and keep you updated every step of the way.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Temporary Skill Shortage (TSS) Visa <br />
          (Subclass 482) FAQs
        </div>
        <div className="text-blue mt-3 font-normal text-xl lg:text-[22px] text-center  hellix-font">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.{" "}
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. What is the Subclass 482 visa? 
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                The Subclass 482 visa is a temporary visa for skilled workers to address labor shortages in Australia.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2. What are the benefits of the Subclass 482 visa? 
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Benefits include temporary residence, work for a sponsoring employer, and a pathway to permanent residence.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. Who is eligible for the Subclass 482 visa? 
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Skilled workers with specialized or critical skills, sponsored by an approved employer.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. What are the occupation lists for the Subclass 482 visa? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Medium and Long-Term Strategic Skills List (MLTSSL), Short-Term Skilled Occupation List (STSOL), and Regional Occupation List (ROL).
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. What streams are available under the Subclass 482 visa? 
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Short-Term Stream (up to 2 years), Medium-Term Stream (up to 4 years), and Labour Agreement Stream.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                6. What are the sponsorship requirements for the Subclass 482
                visa? {" "}
              </h2>
              {activeIndex === 6 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Employers must be approved sponsors, demonstrate a genuine need for the position, and conduct Labour Market Testing (LMT).
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                7. How do I apply for the Subclass 482 visa? 
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Submit a sponsorship application, nomination, and visa application, with required documents and biometrics.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                8. How long does the Subclass 482 visa application process
                take? {" "}
              </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Processing times vary, typically 3-6 months.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                9. Can I transition to permanent residence from the Subclass 482
                visa? {" "}
              </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Yes, through the Subclass 191 Permanent Residence (Skilled Regional) visa.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                10. Can I transition to permanent residence from the Subclass
                482 visa? {" "}
              </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Our experts provide guidance on eligibility, sponsorship, nomination, and visa applications.
                </p>
              </div>
            )}
          </div>
    
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default EmployeeTemporarySkilledVisa;
