import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home/home";
import Contactus from "./components/contactPage/contactus";
import Navbar from "./components/navbar";
import AboutUs from "./components/aboutPage/aboutUs";
import SkillIndependentVisa from "./components/skill-independent-visa/skill-independent-visa";
import SkillNominatedVisa from "./components/skill-nominated-visa/skill-Nominated-Visa";
import SkillWorkReligion from "./components/skill-worked-religion/skill-worked-religion";
import StudentVisa from "./components/student-visa/student-visa";
import StudentGuardianVisa from "./components/student-guardian-visa/student-guardian-visa";
import SkillAssesment from "./components/skill-assesment/skill-assement";
import ParentVisa from "./components/parent-visa/parent-visa";
import FamilyVisa from "./components/family-visa/family-visa";
import VisitorVisa from "./components/visitor-visa/visitor-visa";
import PartnerVisa from "./components/partner-visa/partner-visa";
import EmployeeTrainingVisa from "./components/employee-training-visa/employee-training-visa";
import EmployeeSkilledReligion from "./components/employee-skilled-religion/employee-skilled-religion";
import EmployeeTemporaryVisa from "./components/employee-temporary-visa/employee-temporary-visa";
import TemporaryGraduateVisa from "./components/temporary-graduate-visa/temporary-graduate-visa";
import EmployeeReligionMigration from "./components/employee-religion-migration/employe-religion-migration";
import EmployeeTemporarySkilledVisa from "./components/employee-temporary-skilled-vis/employee-temporary-skilled-visa";
import EmployerSponserVisa from "./components/employee-sponser-nominated-visa/employee-sponser";
import EmployeeNominatedSchemaVisa from "./components/employee-nominated-schema-visa/employee-nominated-schema-visa";
import ScrollToTop from "./components/scrollToTop";

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        {/* Add ScrollToTop Component */}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/contact"
              element={
                <>
                  <Navbar />
                  <Contactus />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/about"
              element={
                <>
                  <Navbar />
                  <AboutUs />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/skill-independent-visa"
              element={
                <>
                  <Navbar />
                  <SkillIndependentVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/skill-nominated-visa"
              element={
                <>
                  <Navbar />
                  <SkillNominatedVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/skill-work-religion"
              element={
                <>
                  <Navbar />
                  <SkillWorkReligion />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/student-visa"
              element={
                <>
                  <Navbar />
                  <StudentVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/student-guardian-visa"
              element={
                <>
                  <Navbar />
                  <StudentGuardianVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/student-assesment"
              element={
                <>
                  <Navbar />
                  <SkillAssesment />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/parent-visa"
              element={
                <>
                  <Navbar />
                  <ParentVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/family-visa"
              element={
                <>
                  <Navbar />
                  <FamilyVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/partner-visa"
              element={
                <>
                  <Navbar />
                  <PartnerVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/visitor-visa"
              element={
                <>
                  <Navbar />
                  <VisitorVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/employee-training-visa"
              element={
                <>
                  <Navbar />
                  <EmployeeTrainingVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/employee-skilled-religion"
              element={
                <>
                  <Navbar />
                  <EmployeeSkilledReligion />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/employee-temporary-visa"
              element={
                <>
                  <Navbar />
                  <EmployeeTemporaryVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/temporary-Graduate-visa"
              element={
                <>
                  <Navbar />
                  <TemporaryGraduateVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/employee-religion-migration"
              element={
                <>
                  <Navbar />
                  <EmployeeReligionMigration />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/employee-temporary-skilled-visa"
              element={
                <>
                  <Navbar />
                  <EmployeeTemporarySkilledVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/employee-sponser-visa"
              element={
                <>
                  <Navbar />
                  <EmployerSponserVisa />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/employee-nominated-schema-visa"
              element={
                <>
                  <Navbar />
                  <EmployeeNominatedSchemaVisa />
                  <Footer />
                </>
              }
            ></Route>
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
