import logo from "../images/home/navbar-logo.png";
import toggleIcon from "../images/home/toggle-icon.svg";
import arrow from "../images/home/header-responsive-arrow.svg";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEmployeesOpen, setIsEmployeesOpen] = useState(false);
  const [isFamiliesOpen, setIsFamiliesOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };
  const employeesLinks = [
    { name: "Subclass - 186", url: "/employee-nominated-schema-visa" },
    { name: "Subclass - 187", url: "/employee-religion-migration" },
    { name: "Subclass - 400", url: "/employee-temporary-visa" },
    { name: "Subclass - 494", url: "/employee-skilled-religion" },
    { name: "Subclass - 407", url: "/employee-training-visa" },
    { name: "Subclass - 482", url: "/employee-temporary-skilled-visa" },
  ];

  const familiesLinks = [
    { name: "Subclass - 189", url: "/skill-independent-visa" },
    { name: "Subclass - 190", url: "/skill-nominated-visa" },
    { name: "Subclass - 491", url: "/skill-work-religion" },
    { name: "Subclass - 600", url: "/visitor-visa" },
    { name: "Subclass - 485", url: "/temporary-Graduate-visa" },
    { name: "Sub - 820/801 & 309 / 100", url: "/partner-visa" },
    { name: "Subclass - 103 & 143", url: "/parent-visa" },
    { name: "Subclass - 500 & 590", url: "/student-visa" },
    { name: "Family Visa", url: "/family-visa" },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header style={{ backgroundColor: "#F4FFFC" }}>
      <div className="container max-w-[1440px] mx-auto py-6 lg:py-4 px-4 xl:px-14">
        <div className="flex justify-between items-center">
          {/* Logo Section */}
          <div className="lg:block hidden">
          <Link to="/">   <img
              src={logo}
              alt="Immigate Global Logo"
              className="w-[126px] h-[73px]"
            />
            </Link>
          </div>

          {/* Toggle Icon for mobile view */}

          <div className="lg:hidden">
            <Link to="/">
              <img
                src={logo}
                alt="Immigate Global Logo"
                className="w-[126px] h-[73px]"
              />{" "}
            </Link>
          </div>

          <div className="hidden lg:flex w-full max-w-[917px]  rounded-[67px] px-2 xl:px-6 py-4 space-x-4 justify-around items-center">
            <Link
              to="/"
              className="relative group text-blue hellix-font text-[15px] font-semibold transition-all after:content-[''] after:block after:w-0 after:h-[3px] after:bg-blue after:transition-all  after:duration-300  hover:after:w-full"
            >
              Home
            </Link>

            {/* For Employers Dropdown */}
            <div
              className="relative "
              onMouseEnter={() => setIsEmployeesOpen(true)}
              onMouseLeave={() => setIsEmployeesOpen(false)}
            >
              <Link
                to=""
                className="relative group text-blue hellix-font text-[15px] font-semibold transition-all after:content-[''] after:block after:w-0 after:h-[3px] after:bg-blue after:transition-all after:duration-300  hover:after:w-full"
              >
                For Employers
              </Link>
              {isEmployeesOpen && (
                <div
                  className="absolute w-fit -left-4  bottom-0 translate-y-full bg-white shadow-lg rounded-lg py-3 px-4 space-y-2 z-10"
                  onMouseEnter={() => setIsEmployeesOpen(true)}
                  onMouseLeave={() => setIsEmployeesOpen(false)}
                  style={{
                    minWidth: "max-content",
                    whiteSpace: "nowrap",
                    backgroundColor: "#F4FFFC",
                  }}
                >
                  {employeesLinks.map((link, index) => (
                    <Link
                      key={index}
                      to={link.url}
                      className="block text-blue hellix-font text-[15px] font-semibold hover:text-blue hover:underline transition-all"
                    >
                      {link.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* Individuals & Families Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => setIsFamiliesOpen(true)}
              onMouseLeave={() => setIsFamiliesOpen(false)}
            >
              <Link
                to=""
                className="relative group text-blue hellix-font text-[15px] font-semibold transition-all after:content-[''] after:block after:w-0 after:h-[3px] after:bg-blue after:transition-all after:duration-300  hover:after:w-full"
              >
                Individuals & Families
              </Link>
              {isFamiliesOpen && (
                <div
                  className="absolute -left-4 bottom-0 translate-y-full bg-white shadow-lg rounded-lg py-3 px-4 space-y-2 z-10"
                  onMouseEnter={() => setIsFamiliesOpen(true)}
                  onMouseLeave={() => setIsFamiliesOpen(false)}
                  style={{
                    minWidth: "max-content",
                    whiteSpace: "nowrap",
                    backgroundColor: "#F4FFFC",
                  }}
                >
                  {familiesLinks.map((link, index) => (
                    <Link
                      key={index}
                      to={link.url}
                      className="block text-blue hellix-font text-[15px] font-semibold hover:text-blue hover:underline transition-all"
                    >
                      {link.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <Link
              to="/student-assesment"
              className="relative group text-blue hellix-font text-[15px] font-semibold transition-all after:content-[''] after:block after:w-0 after:h-[3px] after:bg-blue after:transition-all after:duration-300  hover:after:w-full"
            >
              Skill Assessment & Registration
            </Link>
            <Link
              to="/about"
              className="relative group text-blue hellix-font text-[15px] font-semibold transition-all after:content-[''] after:block after:w-0 after:h-[3px] after:bg-blue after:transition-all after:duration-300  hover:after:w-full"
            >
              About Us
            </Link>
            <Link
              to=""
              className="relative group text-blue hellix-font text-[15px] font-semibold transition-all after:content-[''] after:block after:w-0 after:h-[3px] after:bg-blue after:transition-all after:duration-300  hover:after:w-full"
            >
         Blogs
            </Link>
          </div>

          <div
            className={`lg:hidden mt-12 bg-custom-gradients  absolute top-16 left-0 w-full bg-white rounded-b-lg transition-all duration-300 ease-in-out ${
              isMenuOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col items-left  p-4 space-y-4">
              {/* Regular Links */}
              <Link
                to=""
                className="text-white hellix-font text-[15px] font-semibold hover:underline transition-all"
                onClick={closeMenu}
              >
                Home
              </Link>

              {/* For Employers Dropdown */}
              <div>
                <button
                  className="w-full text-left text-white hellix-font text-[15px] font-semibold hover:underline transition-all"
                  onClick={() => toggleDropdown("employees")}
                >
                  For Employers
                </button>
                <div
                  className={`transition-all duration-500 overflow-hidden ${
                    activeDropdown === "employees" ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <div className="pl-4 space-y-2 pt-2">
                    {employeesLinks.map((link, index) => (
                      <Link
                        key={index}
                        to={link.url}
                        className="block text-white hellix-font text-[15px] font-medium hover:underline transition-all"
                      >
                        {link.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              {/* Individuals & Families Dropdown */}
              <div>
                <button
                  className="w-full text-left text-white hellix-font text-[15px] font-semibold hover:underline transition-all"
                  onClick={() => toggleDropdown("families")}
                >
                  Individuals & Families
                </button>
                <div
                  className={`transition-all duration-500 overflow-hidden ${
                    activeDropdown === "families" ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <div className="pl-4 space-y-2 pt-2">
                    {familiesLinks.map((link, index) => (
                      <Link
                        key={index}
                        to={link.url}
                        className="block text-white hellix-font text-[15px] font-medium hover:underline transition-all"
                      >
                        {link.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              {/* Other Links */}
              <Link
                to="/student-assesment"
                className="text-white hellix-font text-[15px] font-semibold hover:underline transition-all"
                onClick={closeMenu}
              >
                Skill Assessment & Registration
              </Link>
              <Link
                to="/about"
                className="text-white hellix-font text-[15px] font-semibold hover:underline transition-all"
                onClick={closeMenu}
              >
                About Us
              </Link>
              <Link
              to=""
              className="text-white hellix-font text-[15px] font-semibold hover:underline transition-all"
            >
         Blogs
            </Link>
            </div>
          </div>

          <div className="hidden lg:block">
            <button className="relative w-[144px] xl:w-[169px] text-center justify-center group text-[#004b6b] hellix-font font-bold text-[15px] h-[44px] xl:h-[52px] rounded-[28px] flex items-center space-x-2 border-2 border-[#004b6b] overflow-hidden">
              {/* Background Animation */}
              <span className="absolute inset-0 bg-white transform scale-x-0 group-hover:scale-x-100 transition-all duration-500 ease-out origin-left z-0"></span>

              {/* Button Content */}
              <span className="relative z-10 group-hover:text-[#004b6b] transition-colors duration-300 ease-in">
                <Link to="/contact">Contact Us</Link>
              </span>

              {/* Arrow Icon */}
              <img
                src={arrow}
                alt="Arrow Icon"
                className="relative z-10 w-4 h-4 transition-all duration-300 ease-out group-hover:translate-x-2 group-hover:brightness-150"
              />
            </button>
          </div>

          <div className="lg:hidden flex gap-4">
            <div className="flex items-center ">
              <button className="relative w-[144px]  text-center justify-center group text-[#004b6b] hellix-font font-bold text-[15px] h-[44px]  rounded-[28px] flex items-center space-x-2 border-2 border-blue overflow-hidden">
                {/* Background Animation */}
                <span className="absolute inset-0 bg-white transform scale-x-0 group-hover:scale-x-100 transition-all duration-1000 ease-in-out origin-left z-0"></span>

                {/* Button Content */}
                <span className="relative z-10 group-hover:text-blue transition-colors duration-700 ease-in">
                  <Link to="/contact">Contact Us</Link>
                </span>
                <img
                  src={arrow}
                  alt="Arrow Icon"
                  className="relative z-10 transition-all duration-500 ease-in "
                />
              </button>
            </div>
            <div
              className=" flex items-center text-black text-2xl font-bold cursor-pointer"
              onClick={toggleMenu}
            >
              {isMenuOpen ? (
                <IoMdClose className="text-3xl" />
              ) : (
                <img src={toggleIcon} alt="Toggle Icon" />
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
