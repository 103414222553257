import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import rightVector from "../../images/individual/right-vector.svg";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import independentVisa from "../../images/individual/SC190.jpg";
import helpIcon from "../../images/individual/helpIcon.svg";
import helpImage from "../../images/individual/guardian.png";
import { FaPlus, FaTimes } from "react-icons/fa";
import unlimitedIcon from "../../images/individual/ic_outline-home-work.svg";
import iconPeople from "../../images/individual/akar-icons_people-group.svg";
import materialIcon from "../../images/individual/material-symbols_family-home-outline.svg";
import medicareIcon from "../../images/individual/icon-park-outline_medical-files.svg";
const SkillNominatedVisa = () => {

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <div
        className="w-full h-90 font-Hellix bg-cover bg-center bg-image"
      >
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Subclass 190 Visa: Skilled Nominated Visa 
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Subclass 190 Visa: Skilled Nominated Visa 
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subclass 189 Visa: Skilled Independent Visa*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            Subclass 190 Visa: Skilled Nominated Visa 
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Subclass 190 visa is a permanent residence visa designed for
            skilled workers who possess qualifications, skills, and experience
            that align with the economic needs of an Australian state or
            territory. This visa category allows eligible applicants to live and
            work in Australia with sponsorship from a state or territory
            government. 
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={independentVisa} alt="banner-image" />
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            Eligibility Criteria:
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          To qualify for the Subclass 190 visa, applicants must: 
        </div>
        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Submit an Expression of Interest (EOI) through SkillSelect 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Receive an invitation to apply for the visa 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Meet the required points score under the points-based system (65
              points) 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Have a nominated occupation listed on the Medium and Long-Term
              Strategic Skills List (MLTSSL) 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Possess the necessary qualifications and experience for the
              nominated occupation 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Demonstrate competent English language proficiency 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Meet health and character requirements 
            </div>
          </div>
        </div>
        <div className="text-blue font-medium text-lg lg:text-[25px] mt-2 hellix-font mt-4">
          State/Territory Nomination Requirements:  
        </div>
        <div className="flex gap-2 mt-2">
          <div>
            <img src={rightVector} alt="" className="min-w-[30px]" />
          </div>
          <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
            Each state/territory has unique nomination requirements. Our
            experienced migration lawyers can guide you through the specific
            requirements for your chosen state/territory. 
          </div>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Benefits
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-4  rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Permanent residence in Australia 
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-4 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-normal text-center hellix-font">
              Unlimited work and study rights 
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-4 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-normal text-center hellix-font">
              Eligibility for Australian citizenship 
            </div>
          </div>
        </div>
        <div className="flex gap-8 justify-center flex-wrap mt-8">
          <div className="shadow-custom-white  mt-4  flex flex-col gap-4 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={materialIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-normal max-md:px-2 text-center hellix-font">
              Sponsorship of family members for permanent residence 
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-4 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={medicareIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl  text-center  max-md:px-2  font-normal hellix-font">
              Access to Medicare and other social benefits  
            </div>
          </div>
        </div>
      </div>

      {/* Application  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Application Process
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className="flex gap-2">
            <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
              Step 1:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              EOI submission through SkillSelect 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 2:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Invitation to apply for the visa 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 3:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              {" "}
              Lodging a complete visa application within 60 days of invitation  
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 4:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Paying the required visa application charge  
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 5:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Providing supporting documentation, including:    
            </div>
          </div>
          <div className="flex gap-2 lg:ml-14 ml-4 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              a.
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Qualification certificates and transcripts 
            </div>
          </div>
          <div className="flex gap-2 lg:ml-14  ml-4  mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              b.
            </div>
            <div className=" hellix-font text-lg lg:text-[22px] font-normal text-blue">
              Experience letters and employment contracts 
            </div>
          </div>
          <div className="flex gap-2 lg:ml-14  ml-4  mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              c.
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              English language test results 
            </div>
          </div>
          <div className="flex gap-2 lg:ml-14 ml-4  mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              d.
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Health insurance documents 
            </div>
          </div>
          <div className="flex gap-2 lg:ml-14 ml-4  mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              e.
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Police certificates 
            </div>
          </div>
        </div>
        <div className="text-blue text-2xl font-semibold hellix-font mt-4">
          Processing Times: 
        </div>
        <div>
          <ul class="lg:ml-4 list-inside">
            <li className="text-blue text-xl lg:text-[22px] hellix-font font-normal">
              Processing times for the Subclass 189 visa vary depending on the
              complexity of the application and the speed of documentation
              submission.
            </li>
          </ul>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Why Choose Immiget Global?  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Why Choose Immiget Global? 
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Our expert migration lawyers provide: 
        </div>
        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Strategic EOI submission and invitation management 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Thorough documentation preparation 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Expert guidance on state/territory nomination 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Efficient communication with the Department of Home Affairs 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Maximizing points score potential 
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Why Choose Immiget Global?  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Will Help You?
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Immiget Global is here to assist at every stage, from sponsorship
          applications to final approvals. Our team provides:
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img src={helpImage} alt="helpIcon" className="h-[100%] " />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-1 ">
            <div>
              <ul class=" list-inside">
                <li className="text-blue  text-xl lg:text-[22px] hellix-font font-semibold">
                  Guidance on Eligibility and Requirements:{" "}
                  <span className="font-normal">
                    We assess both employer and applicant eligibility, ensuring
                    every requirement is met before application.{" "}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Labor Market Testing Support:
                  <span className="font-normal">
                    We offer expertise in preparing and documenting labor market
                    testing requirements to satisfy government criteria.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Tailored Advice on Visa Pathways:
                  <span className="font-normal">
                    Whether temporary or permanent residency is your goal, we
                    help align the best visa pathway to meet long-term
                    aspirations.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Comprehensive Application Management:
                  <span className="font-normal">
                    We handle the paperwork, liaise with the Department of Home
                    Affairs, and keep you updated every step of the way.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Subclass 190 Visa: Skilled Nominated <br />
          Visa FAQs
        </div>
        <div className="text-blue font-normal text-xl lg:text-[22px] text-center  hellix-font">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. What is the SC 190 visa? 
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                The SC 190 visa is a permanent residence visa for skilled workers sponsored by an Australian state or territory.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2.What's the difference between SC 189 and SC 190 visas? 
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                SC 189 is an independent skilled visa, while SC 190 requires state/territory sponsorship.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. What occupations are eligible for the SC 190 visa? 
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Eligible occupations vary by state/territory; check their Skilled Occupation Lists. 
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. What are the eligibility criteria for SC 190 visa? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Criteria include age, English language proficiency, qualifications, experience, skills assessment, and health/character requirements.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. How do I obtain state/territory sponsorship for SC 190? 
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Submit an Expression of Interest (EOI) to the relevant state/territory government.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 6 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                6. What are the benefits of state/territory sponsorship? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Sponsorship increases your chances of receiving an invitation to apply.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 7 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                7. How do I submit an EOI for the SC 190 visa? 
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Submit through SkillSelect, Australia's online immigration system.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 8 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                8. What documents are required for the SC 190 visa application? 
              </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lgfont-normal hellix-font">
                Required documents include passports, birth certificates, qualifications, experience certificates, skills assessment reports, and health/character certificates. 
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 9 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                9. How long does the SC 190 visa application process take? 
              </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Processing times vary, typically 6-12 months.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 10 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                10. Can I apply for the SC 190 visa from outside Australia? 
              </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Yes, you can apply from outside Australia.  
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 11 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(11)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                11. What benefits do SC 190 visa holders receive? 
              </h2>
              {activeIndex === 11 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 11 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Benefits include permanent residence, work/study rights, healthcare access, and potential citizenship. 
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 12 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(12)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                12. Can SC 190 visa holders sponsor family members? 12. Can SC
                190 visa holders sponsor family members? 
              </h2>
              {activeIndex === 12 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 12 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Yes, holders can sponsor eligible family members for permanent residence.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 12 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(13)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                13. How can Immiget Global assist with my SC 190 visa
                application? {" "}
              </h2>
              {activeIndex === 13 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 13 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Our experts provide guidance on eligibility, documentation, submission, and application processing.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default SkillNominatedVisa;
