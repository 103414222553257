import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import familyVisa from "../../images/individual/FamilyVisaOption.jpg";
import childStudentVisa from "../../images/individual/ChildVisa.jpg";
import agedRelative from "../../images/individual/AgedDepenentVisa.jpg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import relativeFamily from "../../images/individual/RemainingRelatuveVisa.jpg";
import toggleIcon from "../../images/individual/fa-solid_stream.svg";
import rightVector from "../../images/individual/right-vector.svg";
import globalHelp from "../../images/individual/immiget-global.png";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";

const FamilyVisa = () => {

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <div
        className="w-full h-90 font-Hellix bg-cover bg-center bg-image"
     
      >
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Family Visa Options 
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Family Visa Options 
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Family Visa Options */}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:pt-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            Family Visa Options
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            Australia’s Family Visa program is designed to reunite families,
            offering several pathways to bring close family members of
            Australian citizens, permanent residents, or eligible New Zealand
            citizens to Australia. In addition to Partner and Parent Visas,
            Family Visa options include Child Visas, Aged Dependent Relative
            Visas, and Remaining Relative Visas.
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={familyVisa} alt="banner-image" />
        </div>
      </div>

      {/* Overview of Parent Visa Pathways    */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:pb-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={toggleIcon} alt="toggle Icon" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Types of Family Visas  
          </div>
        </div>
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              1. Child Visa (Subclass 101 and Subclass 802) 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    These visas allow a dependent child of an Australian
                    citizen, permanent resident, or eligible New Zealand citizen
                    to live in Australia permanently. 
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    The child must be under 25, unmarried, and financially
                    dependent on the sponsoring parent. 
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Subclass 101 is for offshore applicants, while Subclass 802
                    is for children currently in Australia. 
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:w-[40%]">
            <img src={childStudentVisa} alt="Child Student Visa" />
          </div>
        </div>
      </div>

      <div className="container max-w-1440px mx-auto px-4 py-6 xl:pb-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="lg:w-[40%]">
            <img src={agedRelative} alt="Aged Relative" />
          </div>
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              2. Aged Dependent Relative Visa (Subclass 114 and Subclass 838) 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    This visa is available to elderly relatives who rely on an
                    eligible Australian relative for financial support. 
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Applicants must meet dependency criteria, proving that they
                    have no other close relatives capable of providing support
                    outside Australia. 
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container max-w-1440px mx-auto px-4 py-6 xl:pb-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              3. Remaining Relative Visa (Subclass 115 and Subclass 835) 
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    The Remaining Relative Visa is available to individuals
                    whose only near relatives reside in Australia. 
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    This visa pathway is suitable for individuals seeking
                    permanent residency to reunite with family in Australia,
                    provided they do not have immediate family members in other
                    countries. 
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:w-[40%]">
            <img src={relativeFamily} alt="Relative Family" />
          </div>
        </div>
      </div>

      {/* Eligibility Criteria   */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:pb-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical Icon" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Application and Documentation Requirements 
          </div>
        </div>
        <div className="lg:text-[22px] text-2xl text-blue font-normal hellix-font">
          Family Visas generally require substantial evidence demonstrating the
          applicant’s dependency or relationship with their Australian sponsor.
          Common requirements include: 
        </div>
        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Proof of relationship or dependency, including financial evidence
              or cohabitation records. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Detailed assessments for health and character compliance. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Assurance of support by the Australian sponsor, particularly for
              Aged Dependent and Remaining Relative visas. 
            </div>
          </div>
        </div>
      </div>

      {/* How Immiget Global Can Assist  */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font pb-4">
            How Immiget Global Can Assist  
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <div className="lg:w-[50%]">
            <img src={globalHelp} alt="Global Help" />
          </div>
          <div className="lg:w-[50%]">
            <div className="lg:text-[22px] text-xl text-blue font-normal hellix-font">
              At Immiget Global, we understand the importance of family
              reunification and provide dedicated support to each family visa
              case. Our migration specialists ensure that every aspect of your
              application is aligned with Department of Home Affairs
              requirements, delivering personalized support for families
              pursuing long-term settlement in Australia. 
            </div>
            <div className="lg:text-[26px] text-xl text-blue font-semibold hellix-font py-3">
              Contact Immiget Global for Family Visa Assistance 
            </div>
            <div className="lg:text-[22px] text-xl text-blue font-normal hellix-font">
              Navigating the Partner, Parent, and Family Visa processes can be
              complex and emotionally challenging. Immiget Global offers a
              supportive, expert-driven approach, managing each stage of the
              application with the utmost care and precision. Connect with us
              today to explore your options and let our legal experts guide you
              toward reuniting with your loved ones in Australia. 
            </div>
          </div>
        </div>
      </div>

      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center leading-10 text-blue font-semibold hellix-font">
          Parent Visa (Subclass 103 and <br></br>
          Subclass 143) FAQs
        </div>
        <div className="text-blue font-normal text-xl lg:text-[22px] text-center  hellix-font pt-3">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              What is a Family Visa?              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                A visa for family members of Australian citizens, permanent residents, or eligible New Zealand citizens.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              2. What types of Family Visas are available?
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Partner, Parent, Child, and Other Family Visas.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              What is a Partner Visa?              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                For spouses, partners, or fiancés of Australian citizens, permanent residents, or eligible New Zealand citizens.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              4. What subclasses are available?              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Subclass 820/801, Subclass 309/100.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              What is a Parent Visa?              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                For parents of Australian citizens, permanent residents, or eligible New Zealand citizens.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 6 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              6. What subclasses are available?              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Subclass 143, Subclass 173, Subclass 804, Subclass 884.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 7 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              7. What is a Child Visa?
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                For children of Australian citizens, permanent residents, or eligible New Zealand citizens.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 8 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              8. What subclasses are available?
              </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Subclass 101, Subclass 802.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 9 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              9. What other Family Visas are available?
              </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Carer Visa (Subclass 836), Remaining Relative Visa (Subclass 115), Aged Dependent Relative Visa (Subclass 114).
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 10 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              10. Who is eligible for a Family Visa?
              </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Family members of Australian citizens, permanent residents, or eligible New Zealand citizens.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 11 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(11)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              11. How do I apply for a Family Visa?
              </h2>
              {activeIndex === 11 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 11 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Sponsor submits sponsorship application, followed by applicant's visa application.
                </p>
              </div>
            )}
          </div>
            {/* Accordion Section 11 */}
            <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(12)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              12. How long does the Family Visa application process take?              </h2>
              {activeIndex === 12 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 12 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Varies (typically 2-24 months).                </p>
              </div>
            )}
          </div>
            {/* Accordion Section 11 */}
            <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(13)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              13. What are the benefits of a Family Visa?
              </h2>
              {activeIndex === 13 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 13 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Temporary or permanent residence, work, and study in Australia.                </p>
              </div>
            )}
          </div>
            {/* Accordion Section 11 */}
            <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(14)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              14. How can Immiget Global assist with my Family Visa application?
              </h2>
              {activeIndex === 14 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 14 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Expert guidance on eligibility, document preparation.                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default FamilyVisa;
