import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import rightVector from "../../images/individual/right-vector.svg";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";
import unlimitedIcon from "../../images/individual/ic_outline-home-work.svg";
import iconPeople from "../../images/individual/akar-icons_people-group.svg";
import employeeReligion from "../../images/individual/SC-494.jpg";
import guardianImage from "../../images/individual/guardian.png";

const EmployeeSkilledReligion = () => {

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <div
        className="w-full h-90 font-Hellix bg-cover bg-center bg-image"
     
      >
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
            Subclass 494 Skilled Employer-Sponsored <br/>Regional (Provisional) Visa  
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Visas
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
              Subclass 494 Skilled Employer-Sponsored Regional (Provisional) Visa  
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subclass 189 Visa: Skilled Independent Visa*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
          Subclass 494 Skilled Employer-Sponsored Regional (Provisional) Visa  
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
          The Subclass 494 visa is a provisional visa for skilled workers who want to live and work in regional Australia. 
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={employeeReligion} alt="banner-image" />
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Key Features
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[296px] h-[220px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
            Provisional visa with a validity period of 5 years 
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[220px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl px-2 font-normal text-center hellix-font">
            Pathway to permanent residence through Subclass 191 
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[220px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
            Requires nomination from an approved sponsor 
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[220px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
            Points-tested visa with a minimum of 65 points required
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            Eligibility Criteria:
          </div>
        </div>
        <div className=" ml-4 mt-3 text-blue text-lg xl:text-[22px] font-normal hellix-font">
          The Employer Sponsor Nomination Visa has requirements for both
          sponsoring employers and nominated workers:
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
            H- Age: Under 45 years (exemptions apply) 
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
            I- English language proficiency: Competent English 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
            E- Qualifications and experience: Relevant to occupation 

            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
            H- Health and character: Meet standard health and character requirements 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
            H- Debt and compliance: No outstanding debts to the Australian Government             </div>
          </div>
        </div>
      </div>

      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 xl:py-4 lg:px-14">
        <div className=" ml-4  text-blue text-lg xl:text-[22px] font-semibold hellix-font">
          Occupational Training: 
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
            H- Employer must be an approved sponsor 
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
            I- Labour Market Testing (LMT) required 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
            F- Employer must demonstrate a genuine need for the position 
            </div>
          </div>
        </div>
      </div>
      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 xl:py-4 lg:px-14">
        <div className=" ml-4  text-blue text-lg xl:text-[22px] font-semibold hellix-font">
          Nomination Requirements: 
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
            H- Medium and Long-Term Strategic Skills List (MLTSSL) 
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
            I- Short-Term Skilled Occupation List (STSOL) 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
            F- Regional Occupation List (ROL) 
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 xl:py-4 lg:px-14">
        <div className=" ml-4  text-blue text-lg xl:text-[22px] font-semibold hellix-font">
        Eligible Regional Areas: 
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
            All areas outside Sydney, Melbourne, Brisbane, Gold Coast, and Perth 
            </div>
          </div>

         
        </div>
      </div>

      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Benefits
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
            Live and work in regional Australia 
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl px-2 font-normal text-center hellix-font">
            Pathway to permanent residence 
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
            Access to Medicare and healthcare services 
            </div>
          </div>
        </div>
      </div>
      {/* Application  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Application Process
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className="flex gap-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              <span className="font-semibold">Step 1: </span> Employer submits a nomination application 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              <span className="font-semibold">Step 2: </span>Applicant submits a visa application
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              {" "}
              <span className="font-semibold">Step 3: </span>  Provide required documents and biometrics  
            </div>
          </div>
        </div>
      </div>

      <div className=" container max-w-1440px  mx-auto px-4 py-2 lg:px-14">
        <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
          Processing Times: 
        </div>
        <div>
          <ul class="lg:ml-4 list-inside">
            <li className="text-blue text-xl lg:text-[22px] hellix-font font-normal">
            Varying processing times, typically 6-12 months 
            </li>
          </ul>
        </div>
      </div>

      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            Immiget Global Assistance: 
          </div>
        </div>
        <div className=" ml-4 mt-3 text-blue text-lg xl:text-[22px] font-normal hellix-font">
          Our experienced migration lawyers provide expert guidance on:  
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Sponsorship applications 
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Visa applications 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Compliance and regulations  
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
    {/* Why Choose Immiget Global?  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Will Help You?
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Immiget Global is here to assist at every stage, from sponsorship
          applications to final approvals. Our team provides:
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img src={guardianImage} alt="helpIcon" className="h-[100%] " />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-1 ">
            <div>
              <ul class=" list-inside">
                <li className="text-blue  text-xl lg:text-[22px] hellix-font font-semibold">
                  Guidance on Eligibility and Requirements:{" "}
                  <span className="font-normal">
                    We assess both employer and applicant eligibility, ensuring
                    every requirement is met before application.{" "}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Labor Market Testing Support:
                  <span className="font-normal">
                    We offer expertise in preparing and documenting labor market
                    testing requirements to satisfy government criteria.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Tailored Advice on Visa Pathways:
                  <span className="font-normal">
                    Whether temporary or permanent residency is your goal, we
                    help align the best visa pathway to meet long-term
                    aspirations.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Comprehensive Application Management:
                  <span className="font-normal">
                    We handle the paperwork, liaise with the Department of Home
                    Affairs, and keep you updated every step of the way.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
        Subclass 494 Skilled Employer-Sponsored <br/>Regional (Provisional) Visa FAQs
        </div>
        <div className="text-blue mt-3 font-normal text-xl lg:text-[22px] text-center  hellix-font">
        Our migration agents are experts in navigating complex processes, offering tailored solutions with professionalism and care. Trust us to provide transparent, efficient, and reliable support for a seamless journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              1. What is the Subclass 494 visa? 
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                A provisional visa for skilled workers to live and work in regional Australia.             </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              2. What is the validity period of the Subclass 494 visa? 
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                5 years.               </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              3. Who is eligible for the Subclass 494 visa? 
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Skilled workers nominated by an approved employer, meeting age, English language, qualification, and experience requirements.               </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              4. How do I apply for the Subclass 494 visa? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Employer submits a nomination application, followed by applicant's visa application.                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              5. What documents are required for the Subclass 494 visa?               </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Passport, proof of qualifications, experience, English language proficiency, health insurance.
                </p>
              </div>
            )}
          </div>
              {/* Accordion Section 5*/}
              <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              6. How long does the Subclass 494 visa application process take?               </h2>
              {activeIndex === 6 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Typically 6-12 months.
                </p>
              </div>
            )}
          </div>
              {/* Accordion Section 5*/}
              <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              7. What are the benefits of the Subclass 494 visa?              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Live and work in regional Australia, pathway to permanent residence.
                </p>
              </div>
            )}
          </div>
              {/* Accordion Section 5*/}
              <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              8. Can I apply for permanent residence after receiving the Subclass 494 visa?               </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Yes, through Subclass 191 Permanent Residence (Skilled Regional) visa.
                </p>
              </div>
            )}
          </div>
              {/* Accordion Section 5*/}
              <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              9. What areas are considered regional for the Subclass 494 visa?               </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                All areas outside Sydney, Melbourne, Brisbane, Gold Coast, and Perth.
                </p>
              </div>
            )}
          </div>
              {/* Accordion Section 5*/}
              <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              10. How can Immiget Global assist with my Subclass 494 visa application?               </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Expert guidance on eligibility, sponsorship, nomination, and visa applications.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default EmployeeSkilledReligion;
