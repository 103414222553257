import React from "react";
import Banner from "../banner";
// import Question from "../question";
import Partner from "../partner";
import About from "../about";
import Service from "../service.jsx";
import Contact from "../contact";
// import Client from "../client.jsx";

const Home = () => {
  return (
    <>
      <Banner />
      <About />
      <Service />
      <Partner />
      {/* <Client /> */}
      <Contact />
      {/* <Question /> */}
    </>
  );
};

export default Home;
