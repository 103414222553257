import React from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import sponserNomination from "../../images/employee/sponser-nomination.png";
import skillShortage from "../../images/employee/skill-shortage.jpg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import careerAdvancement from "../../images/employee/carrer-adverstiment.svg";
import likeHome from "../../images/employee/material-symbols_family-home-outline.svg";
import skilledEmployee from "../../images/employee/SESR494.jpg";
import employeeNomination from "../../images/employee/ENSVisa186.jpg";
import votingLine from "../../images/employee/RSMS187.jpg";
import toggleIcon from "../../images/individual/fa-solid_stream.svg";
import specailWork from "../../images/employee/SSSV400.jpg";
import traningVisa from "../../images/employee/TrainingVisa407.jpg";
import rightVector from "../../images/individual/right-vector.svg";
import immigetGlobal from "../../images/individual/immiget-global.png";
import applicationProcess from "../../images/individual/application-proccess.svg";
import helpIcon from "../../images/individual/helpIcon.svg";

const EmployerSponserVisa = () => {
  return (
    <div>
      <div
        className="w-full h-90 font-Hellix bg-cover bg-center bg-empspns"
       
      >
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Employer Sponsor Nomination Visa
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Employer Sponsor Nomination Visa
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*The Employer Sponsor Nomination Visa program:*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[60%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            The Employer Sponsor Nomination Visa program:
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Employer Sponsor Nomination Visa program is an Australian
            migration pathway that enables Australian employers to sponsor
            skilled foreign workers to fill roles that cannot be met by the
            local workforce. This visa provides a unique opportunity for both
            businesses and skilled migrants, allowing employers to access a
            broader pool of talent and giving international professionals the
            chance to contribute to Australia’s economy and build a life in a
            thriving, multicultural environment. At Immiget Global, we
            specialize in guiding both employers and skilled applicants through
            the complexities of this visa process, ensuring compliance and a
            streamlined experience.
          </div>
        </div>
        <div className="xl:w-[40%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={sponserNomination} alt="banner-image" />
        </div>
      </div>

      {/* Visa Streams  */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={toggleIcon} alt="toggle Icon" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Visa Streams
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] my-2 hellix-font">
          The Employer Sponsor Nomination Visa offers multiple pathways designed
          to cater to various skill levels and business needs. These include:
        </div>
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              1. Temporary Skill Shortage (TSS) Visa (Subclass 482)
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Designed to address short-term and medium-term skill gaps,{" "}
                    <span className="font-normal">
                      the TSS visa enables employers to sponsor qualified
                      candidates for up to four years, depending on the
                      occupation and the specific stream selected.
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Short-Term Stream:{" "}
                    <span className="font-normal">
                      This stream allows employers to fill short-term skill
                      shortages with overseas workers in certain occupations for
                      up to two years or four years if an international trade
                      obligation applies.
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Medium-Term Stream:{" "}
                    <span className="font-normal">
                      Aimed at fulfilling medium- to long-term skill shortages,
                      this stream provides a pathway to permanent residency
                      after three years in certain high-demand occupations.
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Labour Agreement Stream:{" "}
                    <span className="font-normal">
                      For situations where standard visa criteria do not meet
                      specific labor needs, businesses can negotiate a labor
                      agreement with the Australian government to sponsor
                      foreign workers.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="xl:w-[40%]">
            <img src={skillShortage} alt="skill Shortage" />
          </div>
        </div>
      </div>

      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[40%]">
            <img src={employeeNomination} alt="Employee Nomination" />
          </div>
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              2. Employer Nomination Scheme (ENS) Visa (Subclass 186)
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    <span className="font-normal">
                      {" "}
                      A permanent residence visa, the ENS visa is available for
                      skilled workers nominated by an Australian employer under
                      either the Direct Entry Stream or the
                    </span>{" "}
                    Temporary Residence Transition Stream.
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Direct Entry Stream:{" "}
                    <span className="font-normal">
                      Suitable for those who have not previously worked in
                      Australia or have minimal Australian work experience.
                    </span>
                  </div>
                </li>
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
                  <span className="text-blue">•</span>
                  <div>
                    Temporary Residence Transition Stream:{" "}
                    <span className="font-normal">
                      Tailored for individuals who have held a TSS visa for
                      three years and meet the required skill and language
                      criteria.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              3. Regional Sponsored Migration Scheme (RSMS) Visa (Subclass 187)
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue">•</span> This visa is tailored for
                  skilled workers moving to regional areas, where there is a
                  high demand for specific skills. It offers both Direct Entry
                  and Temporary Residence Transition options, and successful
                  applicants gain permanent residency.
                </li>
              </ul>
            </div>
          </div>
          <div className="xl:w-[40%]">
            <img src={votingLine} alt="voting" />
          </div>
        </div>
      </div>

      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[40%]">
            <img src={specailWork} alt="Specail Work" />
          </div>

          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              4. Subclass 400 Temporary Work (Short Stay Specialist) Visa
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue text-xl lg:text-[22px]">•</span>
                  The Subclass 400 Temporary Work (Short Stay Specialist) Visa
                  allows individuals with highly specialized skills, knowledge,
                  or experience to come to Australia for short-term work. This
                  visa is ideal for people undertaking non-ongoing work,
                  offering a streamlined pathway for employers who require
                  unique expertise that cannot be found within the local
                  workforce. It allows holders to work in Australia for up to
                  three months, although some cases may permit a stay of up to
                  six months.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              5. Subclass 494 – Skilled Employer Sponsored Regional
              (Provisional) Visa
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue text-xl lg:text-[22px]">•</span>
                  The Subclass 494 Skilled Employer Sponsored Regional
                  (Provisional) Visa is designed for skilled workers willing to
                  live and work in regional Australia, where there is high
                  demand for specific talents. This visa requires sponsorship by
                  an approved Australian employer and offers a pathway to
                  permanent residency through the Subclass 191 visa after three
                  years, provided applicants meet certain conditions, such as
                  continuing to work and live in a designated regional area.
                </li>
              </ul>
            </div>
          </div>
          <div className="xl:w-[40%]">
            <img src={skilledEmployee} alt="Skilled Employee" />
          </div>
        </div>
      </div>

      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[40%]">
            <img src={traningVisa} alt="Traning Visa" />
          </div>

          <div className="xl:w-[60%]">
            <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
              6. Subclass 407 – Training Visa
            </div>
            <div>
              <ul className="list-inside">
                <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-normal mt-2">
                  <span className="text-blue text-xl lg:text-[22px]">•</span>
                  The Subclass 407 Training Visa offers a structured pathway for
                  individuals to improve their skills through workplace training
                  or professional development in Australia. This visa is
                  suitable for applicants who need to complete specific training
                  to advance their careers or meet occupational registration
                  standards. It allows holders to participate in work-based
                  training programs provided by Australian employers, helping
                  bridge skill gaps and enhance qualifications for future
                  employment.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Benefits
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white mt-4 px-2 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[410px] h-[290px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-[22px] font-semibold text-center  hellix-font">
              Pathway to Permanent Residency
            </div>
            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Many Employer Sponsor Visa pathways offer options for permanent
              residency, making it a viable long-term settlement solution.
            </div>
          </div>
          <div className="shadow-custom-white  mt-4 px-2  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[290px]">
            <div>
              <img src={careerAdvancement} alt="Career-Advancement" />
            </div>

            <div className="text-blue text-[22px] font-semibold text-center  hellix-font">
              Career Advancement
            </div>
            <div className="text-blue text-xl font-normal text-center  hellix-font">
              provides skilled professionals with significant career
              opportunities in Australia’s thriving economy.
            </div>
          </div>
          <div className="shadow-custom-white px-2 mt-4 flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[290px]">
            <div>
              <img src={likeHome} alt="Like Home" />
            </div>

            <div className="text-blue text-[22px] font-semibold text-center hellix-font">
              Support for Regional Development
            </div>
            <div className="text-blue text-xl font-normal text-center hellix-font">
              Programs like the RSMS visa are aligned with government efforts to
              grow regional Australia, supporting both employers and migrants.
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Requirements for the Subclass 600 Visa   */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Eligibility
          </div>
        </div>
        <div className="lg:text-[22px] text-lg  text-blue font-normal hellix-font py-2">
          The Employer Sponsor Nomination Visa has requirements for both
          sponsoring employers and nominated workers:
        </div>
        <div className="lg:text-[25px] text-lg  text-blue font-semibold hellix-font py-2">
          1. For Employers:
        </div>
        <div className="lg:ml-4 mt-2">
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Sponsorship Approval:
              </span>
              The employer must be an approved sponsor or willing to go through
              the sponsorship approval process.
            </div>
          </div>
          <div className="flex gap-2 my-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Labor Market Testing (LMT):
              </span>
              Employers must show efforts to hire locally by conducting labor
              market testing unless exempt.{" "}
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Commitment to Training:
              </span>{" "}
              The employer should demonstrate a commitment to the ongoing
              training of Australian workers, often by making annual
              contributions to an industry training fund.
            </div>
          </div>
        </div>

        <div className="lg:text-[25px] text-lg  text-blue font-semibold hellix-font py-2">
          2. For Skilled Applicants:
        </div>
        <div className="lg:ml-4 mt-2">
          <div className="flex gap-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Relevant Skills and Qualifications:
              </span>
              Applicants must have the necessary skills, qualifications, and
              licensing (if applicable) for the nominated position.
            </div>
          </div>
          <div className="flex gap-2 my-2">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                English Language Proficiency:{" "}
              </span>
              Minimum language requirements vary by stream but generally require
              proficiency to ensure effective communication.{" "}
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              <span className="text-blue text-lg lg:text-[22px] font-semibold hellix-font">
                Health and Character Requirements:
              </span>
              Health and Character Requirements: Standard Australian health and
              character standards must be met to ensure public health and
              safety.{" "}
            </div>
          </div>
        </div>
      </div>

      {/* Application Process   */}
      <div className=" container max-w-1440px  mx-auto px-4  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={applicationProcess} alt=" Application Process" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Application Process
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
            Step 1: Sponsorship Application:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            The employer submits a sponsorship application to become an approved
            sponsor, including demonstrating their need for overseas workers and
            commitment to hiring locally.
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 2: Nomination Application:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            Once the sponsorship is approved, the employer nominates a specific
            position for sponsorship, providing evidence that the role meets
            labor market needs and aligns with immigration standards.
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 3: Visa Application:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            {" "}
            The nominated individual applies for the visa, submitting personal
            documents, proof of skills and experience, and meeting health and
            character requirements.
          </div>

          <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
            Step 4: Decision and Granting of Visa:
          </div>
          <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue py-2">
            The Department of Home Affairs assesses the application. Upon
            approval, the applicant can begin working in Australia as per the
            conditions of the visa.
          </div>
        </div>
      </div>

      {/* How Immiget Global Can Assist with Your Subclass 600 Application */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Will Help You?
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Immiget Global is here to assist at every stage, from sponsorship
          applications to final approvals. Our team provides:
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img
              src={immigetGlobal}
              alt="Immiget Global"
              className="h-[100%]"
            />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-4">
            <ul className="list-inside">
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold py-3">
                <span>&bull;</span>
                <div>
                  Guidance on Eligibility and Requirements:{" "}
                  <span className="font-normal">
                    We assess both employer and applicant eligibility, ensuring
                    every requirement is met before application.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Labor Market Testing Support:
                  <span className="font-normal">
                    We offer expertise in preparing and documenting labor market
                    testing requirements to satisfy government criteria.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold py-2">
                <span>&bull;</span>
                <div>
                  Tailored Advice on Visa Pathways:
                  <span className="font-normal">
                    Whether temporary or permanent residency is your goal, we
                    help align the best visa pathway to meet long-term
                    aspirations.
                  </span>
                </div>
              </li>
              <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold">
                <span>&bull;</span>
                <div>
                  Comprehensive Application Management:
                  <span className="font-normal">
                    We handle the paperwork, liaise with the Department of Home
                    Affairs, and keep you updated every step of the way.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default EmployerSponserVisa;
