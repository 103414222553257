import React from "react";
import whiteArrow from "../images/home/white-Arrow.svg";
import callIcon from "../images/home/call-logo.svg";
import emailboxIcon from "../images/home/email-logo.svg";
import facebook from "../images/footer/facebook-logo.svg";
import instagram from "../images/footer/instagram-logo.svg";
import linkedin from "../images/footer/linkedin-logo.svg";
import { Link } from "react-router-dom";
const Banner = () => {
  return (
    <div className=" banner-bg -mt-4 ">
      <div className="  bg-custom-gradient ">
        <div className="container max-w-1440px mx-auto px-4 md:px-14 py-10 max-md:mt-2 flex justify-between">
          {/* Banner Text Section */}
          <div className=" w-full max-w-[709px] md:pt-10">
            {/* Right Section - Social Icons */}
            <div className=" md:pt-10 md:hidden  flex  justify-end gap-4">
              <Link
                to="https://www.facebook.com/share/1EjTxXWdUU/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  className="w-[17px] h-[17px] hover:scale-110 transition"
                />
              </Link>
              <Link
                to="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={linkedin}
                  alt="LinkedIn"
                  className="w-[17px] h-[17px] hover:scale-110 transition"
                />
              </Link>
              <Link
                to="https://www.instagram.com/immigetglobal/profilecard/?igsh=MXBoaGEzcHk0dHpndg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  className="w-[17px] h-[17px] hover:scale-110 transition"
                />
              </Link>
            </div>
            <div className="hellix-font font-bold text-white text-[58px] max-md:text-center md:text-[76px] leading-tight">
              Simplifying Migration{" "}
              {/* <span className="hellix-font font-bold text-blue  text-[57px] md:text-[75px] leading-tight  text-with-border"> */}
                With Trust & Care
              {/* </span> */}
            </div>
            <div className="text-blue  text-with-border max-md:text-center  font-medium text-[24px] hellix-font py-4">
              A comprehensive evaluation of the client’s immigration needs and
              eligibility for various visa options. Guidance and support.
            </div>

            {/* Buttons Section */}
            <div className="flex max-md:flex-col max-md:items-center max-md:justify-center md:flex gap-6 pt-6">
              <Link to="/about">
                <button className="hellix-font text-lg font-bold hover:bg-blue text-blue w-[200px] h-[54px] rounded-[14px] flex items-center justify-center bg-white border-2 border-blue-500 hover:bg-blue-500 hover:text-white hover:border-blue-500 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl relative">
                  About Us
                </button>
              </Link>
              <Link to="/contact">
                <button className="text-white hellix-font font-bold text-lg w-[208px] h-[54px] rounded-[14px] flex items-center justify-center space-x-2 bg-blue border-2 border-blue-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-700 hover:text-white hover:border-transparent transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl relative">
                  Consult Now
                  <img
                    src={whiteArrow}
                    alt="Arrow"
                    className="ml-2 transition-transform duration-300 ease-in-out transform hover:translate-x-4"
                  />
                </button>
              </Link>
            </div>

            {/* Contact Information Section */}
            <div className=" max-md:flex flex-col max-md:items-center md:flex md:gap-8 pt-5 pb-5 mt-0">
              <div className="hellix-font font-bold text-[18px] text-white">
                <Link
                  to="#"
                  className="flex items-center"
                  onClick={(e) => {
                    window.location.href = "tel:+610370658787";
                    e.preventDefault();
                  }}
                >
                  <img
                    src={callIcon}
                    alt="Phone"
                    className="w-[22px] h-[22px] mr-2"
                  />
                  <span>+61 03 7065 8787</span>
                </Link>
              </div>

              <div className="hellix-font font-bold text-[18px] text-white">
                <Link
                  to="#"
                  className="flex items-center"
                  onClick={(e) => {
                    window.location.href = "mailto:connect@immigetglobal.com";
                    e.preventDefault();
                  }}
                >
                  <img
                    src={emailboxIcon}
                    alt="Email"
                    className="w-[22px] h-[22px] mr-2"
                  />
                  <span>connect@immigetglobal.com</span>
                </Link>
              </div>
            </div>
          </div>

          {/* Right Section - Social Icons */}
          <div className=" pt-10 max-md:hidden  flex flex-col items-center gap-4">
            <Link
              to="https://www.facebook.com/share/1EjTxXWdUU/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebook}
                alt="Facebook"
                className="w-[17px] h-[17px] hover:scale-110 transition"
              />
            </Link>
            <Link
              to="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedin}
                alt="LinkedIn"
                className="w-[17px] h-[17px] hover:scale-110 transition"
              />
            </Link>
            <Link
              to="https://www.instagram.com/immigetglobal/profilecard/?igsh=MXBoaGEzcHk0dHpndg=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagram}
                alt="Instagram"
                className="w-[17px] h-[17px] hover:scale-110 transition"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
