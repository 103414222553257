import React from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg"

const Contactus = () => {

  return (
    <div>
      <div
        className="w-full h-90 font-Hellix bg-cover bg-center bg-image"
      
      >
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white hellix-font   text-center font-semibold text-[24px] md:text-[54px]">
              Contact Us
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white hellix-font  text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img
                  src={arrowIcon}
                  class="w-[5.8px] md:w-auto"
                  alt=""
                />
                  <img
                  src={arrowIcon}
                  class="w-[5.8px] md:w-auto"
                  alt=""
                />
              </div>
              <div className="text-white hellix-font  text-sm flex items-center md:text-lg font-medium uppercase">
                contact us
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default Contactus;
