import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import parentPathway from '../../images/individual/ParentVisa.jpg';
import contributoryParent from '../../images/individual/ContributoryParentVisaSC143.jpg';
import grandparentFamily from '../../images/individual/ParentVisaSC103.jpg';
import toggleIcon from '../../images/individual/fa-solid_stream.svg';
import globalParent from '../../images/individual/immiget-global.png';
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";

const ParentVisa = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div>
            <div
                className="w-full h-90 font-Hellix bg-cover bg-center bg-image"
               
            >
                <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
                    <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
                        <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
                        Parent Visa (Subclass 103 and Subclass 143)  
                        </div>
                        <div className="flex justify-center  gap-2 md:gap-4">
                            <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                                HOME
                            </div>
                            <div className="flex items-center">
                                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                            </div>
                            <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                                Individual & Families
                            </div>
                            <div className="flex items-center">
                                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                            </div>
                            <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                            Parent Visa (Subclass 103 and Subclass 143) 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Parent Visa (Subclass 103 and Subclass 143)  */}
            <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:pt-10 lg:px-14">
                <div className="xl:w-[50%] ">
                    <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
                    Parent Visa (Subclass 103 and Subclass 143) 
                    </div>
                    <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
                    The Parent Visa offers a pathway for parents of Australian citizens or permanent residents to join their children in Australia. The program has options for both permanent and contributory visas, allowing parents to migrate either through a standard visa (Subclass 103) or a Contributory Parent Visa (Subclass 143). Each pathway has specific processing times, financial requirements, and eligibility criteria.  </div>
                </div>
                <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
                    <img src={parentPathway} alt="banner-image" />
                </div>
            </div>

            {/* Overview of Parent Visa Pathways    */}
            <div className="container max-w-1440px mx-auto px-4 py-6 xl:pb-10 lg:px-14">
  <div className="flex gap-2">
    <div className="flex items-center">
      <img src={toggleIcon} alt="toggle Icon" />
    </div>
    <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
    Overview of Parent Visa Pathways 
    </div>
  </div>
  <div className="xl:flex gap-4 justify-center">
    <div className="xl:w-[60%]">
      <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
      1. Parent Visa (Subclass 103) 
      </div>
      <div>
        <ul className="list-inside">
        
          <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
            <span className="text-blue">•</span>
            <div>
            Processing Time:{" "}
              <span className="font-normal">
              This is a standard non-contributory parent visa with a lengthy processing time due to high demand and limited allocation.
              </span>
            </div>
          </li>
          <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
            <span className="text-blue">•</span>
            <div>
            Eligibility:{" "}
              <span className="font-normal">
              Applicants must meet the balance of family test, meaning that at least half of the applicant’s children live permanently in Australia. 
              </span>
            </div>
          </li>
          <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
            <span className="text-blue">•</span>
            <div>
            
Assurance of Support:{" "}
              <span className="font-normal">
              An Australian sponsor (usually the child) must provide an assurance of support, demonstrating their financial capacity to support the parent. 
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
<div className="lg:w-[40%]">
    <img src={grandparentFamily} alt="Grandparent Family"/>
</div>
  
  </div>
</div>



<div className="container max-w-1440px mx-auto px-4 py-6 xl:pb-10 lg:px-14">
  
  <div className="xl:flex gap-4 justify-center">
  <div className="lg:w-[40%]">
    <img src={contributoryParent} alt="Contributory Parent"/>
</div>
    <div className="xl:w-[60%]">
      <div className="text-blue font-medium text-xl lg:text-[28px] my-2 hellix-font">
      2. Contributory Parent Visa (Subclass 143) 
      </div>
      <div>
        <ul className="list-inside">
          <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
            <span className="text-blue">•</span>
            <div>
            Processing Time:{" "}
              <span className="font-normal">
              Contributory visas generally offer a faster pathway, though they involve a higher financial contribution to offset future welfare costs. 
              </span>
            </div>
          </li>
          <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
            <span className="text-blue">•</span>
            <div>
            Eligibility:{" "}
              <span className="font-normal">
              Similar to the Subclass 103, applicants must pass the balance of family test. 
              </span>
            </div>
          </li>
          <li className="flex items-start gap-x-2 text-blue text-xl lg:text-[22px] hellix-font font-semibold mt-2">
            <span className="text-blue">•</span>
            <div>
            
            Visa Cost: {" "}
              <span className="font-normal">
              The Contributory Parent Visa requires a significant financial contribution, payable in two parts, to reduce future demand on Australia’s social services. 
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>

  
  </div>
</div>

                    
              
 
{/* How Immiget Global Can Assist  */}
<div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
  <div className="flex gap-2">
    <div className="flex items-center">
      <img src={helpIcon} alt="elliptical" />
    </div>
    <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font pb-4">
    How Immiget Global Can Assist  
    </div>
  </div>
  <div className="flex flex-col md:flex-row justify-between">
  <div className="lg:w-[50%]">
    <img src={globalParent} alt="Global Parent"/>
  </div>
  <div className="lg:w-[50%] lg:text-[22px] text-xl text-blue font-normal hellix-font">
  Our team provides guidance on selecting the right visa pathway based on your unique family circumstances and ensures comprehensive support with application requirements, particularly the balance of family test, financial commitments, and assurance of support obligations. We ensure that all aspects of your application meet Australia’s stringent parent migration requirements, minimizing delays and providing reassurance throughout the process. 
  </div>
  </div>
  
</div>


            {/*FAQ*/}
            <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
                <div className="lg:text-[38px] text-2xl text-center leading-10 text-blue font-semibold hellix-font">
                Parent Visa (Subclass 103 and <br></br>
                    Subclass 143) FAQs
                </div>
                <div className="text-blue font-normal text-xl lg:text-[22px] text-center  hellix-font pt-3">
                Our migration agents are experts in navigating complex processes, offering tailored solutions with professionalism and care. Trust us to provide transparent, efficient, and reliable support for a seamless journey to your new destination.</div>

                <div className="accordion-container mt-8">
                    {/* Accordion Section 1 */}
                    <div className="accordion">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(1)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            1. What is the Parent Visa? 
                            </h2>
                            {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 1 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                A visa for parents of Australian citizens, permanent residents, or eligible New Zealand citizens.                                </p>
                            </div>
                        )}
                    </div>

                    {/* Accordion Section 2 */}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(2)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            2. What are the subclasses for Parent Visas? 
                            </h2>
                            {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 2 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Subclass 143 (Contributory Parent), Subclass 173 (Contributory Parent (Temporary)), Subclass 804 (Aged Parent), and Subclass 884 (Contributory Aged Parent (Temporary)).                               </p>
                            </div>
                        )}
                    </div>

                    {/* Accordion Section 3 */}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(3)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            3. Who is eligible for the Parent Visa? 
                            </h2>
                            {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 3 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Parents of Australian citizens, permanent residents, or eligible New Zealand citizens.                                </p>
                            </div>
                        )}
                    </div>
                    {/* Accordion Section 4 */}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(4)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            4. What are the age requirements? 
                            </h2>
                            {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 4 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Aged parents (804) must be old enough to receive an Australian age pension.                                </p>
                            </div>
                        )}
                    </div>
                    {/* Accordion Section 5*/}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(5)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            5.  How do I apply for the Parent Visa? 
                            </h2>
                            {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 5 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Sponsor submits sponsorship application, followed by applicant's visa application.                                </p>
                            </div>
                        )}
                    </div>
                    {/* Accordion Section 6 */}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(6)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            6. What documents are required? 
                            </h2>
                            {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 6 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Passport, birth certificate, proof of relationship, health insurance.                                </p>
                            </div>
                        )}
                    </div>
                    {/* Accordion Section 7 */}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(7)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            7. How long does the Parent Visa application process take? 
                            </h2>
                            {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 7 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Typically 2-5 years.                                </p>
                            </div>
                        )}
                    </div>
                    {/* Accordion Section 8 */}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(8)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            8. What are the benefits of the Parent Visa? 
                            </h2>
                            {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 8 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Permanent residence, work, and study in Australia.                                </p>
                            </div>
                        )}
                    </div>
                    {/* Accordion Section 9 */}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(9)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            9. Who can sponsor a Parent Visa applicant? 
                            </h2>
                            {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 9 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Australian citizens, permanent residents, or eligible New Zealand citizens.                                </p>
                            </div>
                        )}
                    </div>
                    {/* Accordion Section 10 */}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(10)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            10. What health and character requirements apply to the Parent Visa? 
                            </h2>
                            {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 10 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Standard health and character requirements.                                </p>
                            </div>
                        )}
                    </div>
                  
                    {/* Accordion Section 11 */}
                    <div className="accordion mt-2">
                        {/* Accordion header */}
                        <div
                            className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
                            onClick={() => toggleAccordion(11)}
                        >
                            <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                            11. How can Immiget Global assist with my Parent Visa application?   
                            </h2>
                            {activeIndex === 11 ? <FaTimes /> : <FaPlus />}
                        </div>

                        {/* Accordion content */}
                        {activeIndex === 11 && (
                            <div className="accordion-content p-4">
                                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                                Expert guidance on eligibility, document preparation.                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Contact />
        </div>
    );
};

export default ParentVisa;
