import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import rightVector from "../../images/individual/right-vector.svg";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import helpIcon from "../../images/individual/helpIcon.svg";
import { FaPlus, FaTimes } from "react-icons/fa";
import unlimitedIcon from "../../images/individual/ic_outline-home-work.svg";
import iconPeople from "../../images/individual/akar-icons_people-group.svg";
import nominatedMigration from "../../images/employee/EmployerNomination.jpg";
import guardianImage from "../../images/individual/guardian.png";
import labourPlaces from "../../images/employee/VisaStream.jpg";
import toggleIcon from "../../images/individual/fa-solid_stream.svg";

const EmployeeNominatedSchemaVisa = () => {

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <div
        className="w-full h-90 font-Hellix bg-cover bg-center bg-image"

      >
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Employer Nomination Scheme (ENS) <br />
              Visa (Subclass 186)  
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Visas
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Employer Nomination Scheme (ENS) Visa (Subclass 186)  
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subclass 189 Visa: Skilled Independent Visa*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            Employer Nomination Scheme (ENS) Visa (Subclass 186)  
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Employer Nomination Scheme (ENS) Visa (Subclass 186) is a
            permanent residence visa for skilled workers nominated by an
            Australian employer. 
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={nominatedMigration} alt="banner-image" />
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>

      {/* Streams of the Subclass 600 Visitor Visa   */}
      <div className="container max-w-1440px mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={toggleIcon} alt="toggle Icon" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Visa Streams
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] my-2 hellix-font">
          The Employer Sponsor Nomination Visa offers multiple pathways designed
          to cater to various skill levels and business needs. These include:
        </div>
        <div className="xl:flex gap-4 justify-center">
          <div className="xl:w-[60%]">
            <div className="text-blue font-normal text-xl lg:text-[24px] my-2 hellix-font">
              <span className="font-medium">1. Direct Entry Stream: </span>For
              skilled workers with relevant qualifications and experience. 
            </div>
            <div className="text-blue font-normal text-xl lg:text-[24px] my-2 hellix-font">
              <span className="font-medium">
                2. Temporary Residence Transition Stream:{" "}
              </span>
              For holders of Subclass 457 or 482 visas with at least 2-3 years
              of work experience with the sponsoring employer.
            </div>
            <div className="text-blue font-normal text-xl lg:text-[24px] my-2 hellix-font">
              <span className="font-medium">3. Labour Agreement Stream: </span>{" "}
              For skilled workers sponsored by employers under a labour
              agreement.
            </div>
          </div>

          <div className="xl:w-[40%]">
            <img src={labourPlaces} alt="Tourist Place" />
          </div>
        </div>
      </div>
      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Key Features
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Permanent residence in Australia  
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl px-2 font-normal text-center hellix-font">
              Work for the sponsoring employer  
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[206px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              Pathway to citizenship  
            </div>
          </div>
        </div>
        <div className="flex justify-center flex-wrap gap-8">
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[166px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              Requires nomination from an approved sponsor  
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[166px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              Points-tested visa with a minimum of 65 points required  
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            Eligibility Criteria:
          </div>
        </div>
        <div className=" ml-4 mt-3 text-blue text-lg xl:text-[22px] font-normal hellix-font">
          The Employer Sponsor Nomination Visa has requirements for both
          sponsoring employers and nominated workers:
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              <span className="font-medium">Age:</span> Under 45 years
              (exemptions apply)  
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium">English language proficiency:</span>{" "}
              Competent English (IELTS/TOEFL/PTE/OET)  
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium">
                Qualifications and experience:
              </span>{" "}
              Relevant to occupation (skills assessment required)  
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium">Health and character:</span> Meet
              standard health and character requirements  
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium">Debt and compliance: </span> No
              outstanding debts to the Australian Government  
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 xl:py-4 lg:px-14">
        <div className=" ml-4  text-blue text-lg xl:text-[22px] font-semibold hellix-font">
          Nomination Requirements: 
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Employer must be an approved sponsor 
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Labour Market Testing (LMT) required  
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Employer must demonstrate a genuine need for the position  
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 xl:py-4 lg:px-14">
        <div className=" ml-4  text-blue text-lg xl:text-[22px] font-semibold hellix-font">
          Occupation Lists: 
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Medium and Long-Term Strategic Skills List (MLTSSL)  
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Short-Term Skilled Occupation List (STSOL)  
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Regional Occupation List (ROL)  
            </div>
          </div>
        </div>
      </div>
      {/* Application  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Application Process
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className="flex gap-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              <span className="font-semibold">Step 1: </span>Employer submits a
              nomination application 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              <span className="font-semibold">Step 2: </span> Applicant submits
              a visa application
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              {" "}
              <span className="font-semibold">Step 3: </span> Provide required
              documents and biometrics  
            </div>
          </div>
        </div>
      </div>
      <div className=" container max-w-1440px  mx-auto px-4 py-2 lg:px-14">
        <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
          Processing Times: 
        </div>
        <div>
          <ul class="lg:ml-4 list-inside">
            <li className="text-blue mt-2 text-xl lg:text-[22px] hellix-font font-normal">
              Varying processing times, typically 6-12 months.
            </li>
          </ul>
        </div>
      </div>
      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-6  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Benefits
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Permanent residence in Australia  
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl px-2 font-normal text-center hellix-font">
              Work for the sponsoring employer  
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              Pathway to citizenship  
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[296px] h-[206px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>

            <div className="text-blue text-xl font-normal text-center hellix-font">
              Access to Medicare and healthcare services   
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 xl:py-4 lg:px-14">
        <div className=" ml-4  text-blue text-lg xl:text-[22px] font-semibold hellix-font">
          Transition Options:  
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Pathway to citizenship  
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Eligibility for skilled graduate visas for family members  
            </div>
          </div>
        </div>
      </div>

      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-2 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            Immiget Global Assistance: {" "}
          </div>
        </div>
        <div className=" ml-4 mt-3 text-blue text-lg xl:text-[22px] font-normal hellix-font">
          Our experienced migration lawyers provide expert guidance on:  {" "}
        </div>

        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              Sponsorship applications 
            </div>
          </div>

          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Nomination processes 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              Visa applications 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue  text-lg xl:text-[22px]  font-normal hellix-font">
              Compliance and regulations  
            </div>
          </div>
        </div>
        <div className="text-blue text-lg xl:text-[22px] mt-2  font-normal hellix-font">
          Contact Immiget Global to discuss your Regional Sponsored Migration
          Scheme (RSMS) Visa (Subclass 187) application. 
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Why Choose Immiget Global?  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Will Help You?
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Immiget Global is here to assist at every stage, from sponsorship
          applications to final approvals. Our team provides:
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img src={guardianImage} alt="helpIcon" className="h-[100%] " />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-1 ">
            <div>
              <ul class=" list-inside">
                <li className="text-blue  text-xl lg:text-[22px] hellix-font font-semibold">
                  Guidance on Eligibility and Requirements:{" "}
                  <span className="font-normal">
                    We assess both employer and applicant eligibility, ensuring
                    every requirement is met before application.{" "}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Labor Market Testing Support:
                  <span className="font-normal">
                    We offer expertise in preparing and documenting labor market
                    testing requirements to satisfy government criteria.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Tailored Advice on Visa Pathways:
                  <span className="font-normal">
                    Whether temporary or permanent residency is your goal, we
                    help align the best visa pathway to meet long-term
                    aspirations.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Comprehensive Application Management:
                  <span className="font-normal">
                    We handle the paperwork, liaise with the Department of Home
                    Affairs, and keep you updated every step of the way.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Employer Nomination Scheme (ENS)
          <br />
          Visa (Subclass 186) FAQs{" "}
        </div>
        <div className="text-blue mt-3 font-normal text-xl lg:text-[22px] text-center  hellix-font">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                1. What is the Subclass 186 visa? 
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                The Subclass 186 visa is a permanent residence visa for skilled workers nominated by an Australian employer.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                2. What are the benefits of the Subclass 186 visa? 
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Benefits include permanent residence, work for the sponsoring employer, and a pathway to citizenship.
                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                3. Who is eligible for the Subclass 186 visa? {" "}
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Skilled workers nominated by an approved employer, meeting age, English language, qualification, and experience requirements.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                4. What are the occupation lists for the Subclass 186 visa? {" "}
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Medium and Long-Term Strategic Skills List (MLTSSL), Short-Term Skilled Occupation List (STSOL), and Regional Occupation List (ROL).
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                5. Q: What streams are available under the Subclass 186 visa? {" "}
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Direct Entry Stream, Temporary Residence Transition Stream, and Labour Agreement Stream.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(6)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                6. What are the nomination requirements for the Subclass 186
                visa? {" "}
              </h2>
              {activeIndex === 6 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 6 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Employer must be an approved sponsor, conduct Labour Market Testing (LMT), and demonstrate a genuine need for the position.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(7)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                7. How long does the nomination process take? {" "}
              </h2>
              {activeIndex === 7 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 7 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Varies, typically 2-4 months.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(8)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                8. How do I apply for the Subclass 186 visa? {" "}
              </h2>
              {activeIndex === 8 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 8 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Submit a nomination application, followed by a visa application, with required documents and biometrics.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(9)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                9. How long does the visa application process take? 
              </h2>
              {activeIndex === 9 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 9 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Varies, typically 6-12 months.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(10)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                10. Can I transition to permanent residence from the Subclass
                482 visa? 
              </h2>
              {activeIndex === 10 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 10 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Yes, after meeting residency requirements.
                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(11)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
                11. Can I apply for citizenship after receiving the Subclass 186
                visa? 
              </h2>
              {activeIndex === 11 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 11 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Our experts provide guidance on eligibility, sponsorship, nomination, and visa applications.
                </p>
              </div>
            )}
          </div>
         
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default EmployeeNominatedSchemaVisa;
