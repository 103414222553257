import React, { useState } from "react";
import Contact from "../contact";
import arrowIcon from "../../images/home/arrow-icon.svg";
import ellipticalIcon from "../../images/individual/ellipticalIcon.svg";
import rightVector from "../../images/individual/right-vector.svg";
import benifitIcon from "../../images/individual/benifitIcon.png";
import permanentIcon from "../../images/individual/permanent-resident.svg";
import studentVisa from "../../images/individual/SC-500--Student-Visa.jpg";
import helpIcon from "../../images/individual/helpIcon.svg";
import helpImage from "../../images/individual/guardian.png";
import { FaPlus, FaTimes } from "react-icons/fa";
import unlimitedIcon from "../../images/individual/ic_outline-home-work.svg";
import iconPeople from "../../images/individual/akar-icons_people-group.svg";
import materialIcon from "../../images/individual/material-symbols_family-home-outline.svg";

const StudentVisa = () => {

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <div
        className="w-full h-90 font-Hellix bg-cover bg-center bg-image"
      >
        <div className="max-w-[1440px] px-4 md:px-[75px] m-auto h-full">
          <div className="flex flex-col gap-2 md:gap-2 pt-10 md:pt-20 pb-[90px] h-full justify-center">
            <div className="text-white  hellix-font  text-center font-semibold text-[22px] md:text-[54px]">
              Subclass 500 Student Visa 
            </div>
            <div className="flex justify-center  gap-2 md:gap-4">
              <div className="text-white  hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium">
                HOME
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Individual & Families
              </div>
              <div className="flex items-center">
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
                <img src={arrowIcon} class="w-[5.8px] md:w-auto" alt="" />
              </div>
              <div className="text-white hellix-font  text-[10px]  lg:text-sm flex items-center md:text-lg font-medium uppercase">
                Subclass 500 Student Visa 
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Subclass 189 Visa: Skilled Independent Visa*/}
      <div className="xl:flex gap-4 justify-center container max-w-1440px  mx-auto px-4  py-4 md:py-10 lg:px-14">
        <div className="xl:w-[50%] ">
          <div className="xl:text-[38px] text-[24px] max-xl:text-center text-blue mt-6 font-semibold hellix-font">
            Subclass 500 Student Visa 
          </div>
          <div className=" max-xl:text-center hellix-font text-blue text-lg xl:text-[22px] font-normal ">
            The Subclass 500 Student Visa allows international students to study
            full-time in Australia in registered courses with CRICOS
            (Commonwealth Register of Institutions and Courses for Overseas
            Students) providers. With this visa, students can pursue a variety
            of courses, from primary and secondary education to higher
            education, vocational education, and English language programs. 
          </div>
        </div>
        <div className="xl:w-[50%] max-xl:justify-center max-xl:flex max-xl:mt-4">
          <img src={studentVisa} alt="banner-image" />
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Eligibility Criteria:  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px]  text-2xl text-blue font-semibold hellix-font">
            Eligibility Criteria:
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          To qualify for the Subclass 190 visa, applicants must: 
        </div>
        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px] font-normal hellix-font">
              <span className="font-medium"> Enrollment Confirmation:</span>{" "}
              Must have a Confirmation of Enrollment (CoE) from a registered
              Australian education provider. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium">
                {" "}
                Genuine Temporary Entrant (GTE) Requirement:{" "}
              </span>{" "}
              Applicants need to demonstrate genuine intent to study in
              Australia temporarily. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium"> Financial Capacity:</span> Evidence
              of sufficient funds to cover tuition, travel, and living expenses
              in Australia. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium"> English Proficiency:</span>{" "}
              Applicants must meet the required level of English, which varies
              based on the course. 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg xl:text-[22px]  font-normal hellix-font">
              <span className="font-medium"> Health and Character:</span>{" "}
              Applicants must meet specific health and character requirements. 
            </div>
          </div>
        </div>
      </div>

      {/*Benefits  */}
      <div className=" container max-w-1440px  mx-auto px-4 py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            Key Benefits
          </div>
        </div>
        <div className="text-blue font-normal text-lg  lg:text-[22px] mt-2 hellix-font">
          Many Employer Sponsor Visa pathways offer options for permanent
          residency, making it a viable long-term settlement solution.
        </div>
        <div className="flex flex-wrap justify-between mt-8  ">
          <div className="shadow-custom-white  mt-4 flex flex-col gap-1  rounded-[16px] justify-center items-center w-[410px] h-[250px]">
            <div>
              <img src={permanentIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Study Flexibility:
            </div>
            <div className="text-blue text-xl font-normal text-center  hellix-font">
              Students can enroll in a wide range of programs, from English
              language courses to university degrees. 
            </div>
          </div>
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[250px]">
            <div>
              <img src={unlimitedIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Work Rights:
            </div>
            <div className="text-blue text-xl px-2 font-normal text-center hellix-font">
              Gain valuable work experience by working up to 48 hours per
              fortnight during study periods and unlimited hours during course
              breaks. 
            </div>
          </div>
          <div className="shadow-custom-white mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[250px]">
            <div>
              <img src={iconPeople} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Accompanying Family Members:
            </div>
            <div className="text-blue text-xl font-normal text-center hellix-font">
              Dependents, such as a spouse or children, may be included in the
              visa application. 
            </div>
          </div>
        </div>
        <div className="flex gap-8 justify-center flex-wrap mt-8">
          <div className="shadow-custom-white  mt-4  flex flex-col gap-1 rounded-[16px] justify-center items-center w-[410px] h-[250px]">
            <div>
              <img src={materialIcon} alt="permanent-icon" />
            </div>
            <div className="text-blue text-xl font-semibold text-center  hellix-font">
              Pathway to Other Visas:
            </div>
            <div className="text-blue text-xl px-2  font-normal max-md:px-2 text-center hellix-font">
              The Subclass 500 Visa can open pathways for graduates to explore
              other visa options, such as the Subclass 485 Temporary Graduate
              Visa, upon completing certain courses. 
            </div>
          </div>
        </div>
      </div>

      {/* Application  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={ellipticalIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Application Process
          </div>
        </div>
        <div className=" lg:ml-8 hellix-font mt-3">
          <div className="flex gap-2">
            <div className=" hellix-font text-lg lg:text-[22px] font-semibold text-blue">
              Step 1:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Secure enrollment and obtain a CoE. 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 2:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Gather all required documentation, including proof of funds and
              English proficiency. 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 3:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              {" "}
              Submit an online application through the Department of Home
              Affairs portal. 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 4:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Attend a health examination if requested. 
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className=" hellix-font text-lg lg:text-[22px]  font-semibold text-blue">
              Step 5:
            </div>
            <div className=" hellix-font text-lg lg:text-[22px]  font-normal text-blue">
              Await a decision on your visa application. 
            </div>
          </div>
        </div>
        <div className="text-blue text-2xl font-semibold hellix-font mt-4">
          Processing Times: 
        </div>
        <div>
          <ul class="lg:ml-4 list-inside">
            <li className="text-blue text-xl lg:text-[22px] hellix-font font-normal">
              Processing times for the Subclass 189 visa vary depending on the
              complexity of the application and the speed of documentation
              submission.
            </li>
          </ul>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Why Choose Immiget Global?  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10 lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={benifitIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl  text-blue font-semibold hellix-font">
            Why Choose Immiget Global? 
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Our expert migration lawyers provide: 
        </div>
        <div className="lg:ml-4 mt-4">
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px] font-normal hellix-font">
              Strategic EOI submission and invitation management 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Thorough documentation preparation 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Expert guidance on state/territory nomination 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Efficient communication with the Department of Home Affairs 
            </div>
          </div>
          <div className="flex gap-2 ">
            <div>
              <img src={rightVector} alt="" className="min-w-[30px]" />
            </div>
            <div className="text-blue text-lg lg:text-[22px]  font-normal hellix-font">
              Maximizing points score potential 
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-1440px  mx-auto px-4   lg:px-14">
        <div style={{ border: "1px solid #cde1ea" }}></div>
      </div>
      {/* Why Choose Immiget Global?  */}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="flex gap-2">
          <div className="flex items-center">
            <img src={helpIcon} alt="elliptical" />
          </div>
          <div className="lg:text-[32px] text-2xl text-blue font-semibold hellix-font">
            How Immiget Will Help You?
          </div>
        </div>
        <div className="text-blue font-normal text-lg lg:text-[22px] mt-2 hellix-font">
          Immiget Global is here to assist at every stage, from sponsorship
          applications to final approvals. Our team provides:
        </div>
        <div className="xl:flex gap-6 mt-4">
          <div className="xl:w-[50%]">
            <img src={helpImage} alt="helpIcon" className="h-[100%] " />
          </div>
          <div className="xl:w-[50%] max-xl:mt-4 flex flex-col gap-1 ">
            <div>
              <ul class=" list-inside">
                <li className="text-blue  text-xl lg:text-[22px] hellix-font font-semibold">
                  Guidance on Eligibility and Requirements:{" "}
                  <span className="font-normal">
                    We assess both employer and applicant eligibility, ensuring
                    every requirement is met before application.{" "}
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Labor Market Testing Support:
                  <span className="font-normal">
                    We offer expertise in preparing and documenting labor market
                    testing requirements to satisfy government criteria.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Tailored Advice on Visa Pathways:
                  <span className="font-normal">
                    Whether temporary or permanent residency is your goal, we
                    help align the best visa pathway to meet long-term
                    aspirations.
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-inside">
                <li className="text-blue text-xl lg:text-[22px]  hellix-font font-semibold">
                  Comprehensive Application Management:
                  <span className="font-normal">
                    We handle the paperwork, liaise with the Department of Home
                    Affairs, and keep you updated every step of the way.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*FAQ*/}
      <div className=" container max-w-1440px  mx-auto px-4  py-6 xl:py-10  lg:px-14">
        <div className="lg:text-[38px] text-2xl text-center text-blue font-semibold hellix-font">
          Subclass 500 Student Visa FAQs
        </div>
        <div className="text-blue font-normal text-xl lg:text-[22px] text-center  hellix-font">
          Our migration agents are experts in navigating complex processes,
          offering tailored solutions with professionalism and care. Trust us to
          provide transparent, efficient, and reliable support for a seamless
          journey to your new destination.
        </div>

        <div className="accordion-container mt-8">
          {/* Accordion Section 1 */}
          <div className="accordion">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(1)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              1. How long can I stay in Australia on a Subclass 500 Visa? 
              </h2>
              {activeIndex === 1 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 1 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                The duration of stay depends on the course enrolled. The visa is typically granted for the duration of the course, plus additional time post-completion.                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 2 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(2)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              2. Can I extend my stay on a Subclass 500 Visa? 
              </h2>
              {activeIndex === 2 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 2 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Yes, if you are progressing to a higher level of study or undertaking further studies, you may apply for another Subclass 500 Visa.                </p>
              </div>
            )}
          </div>

          {/* Accordion Section 3 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(3)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              3. Can I include my family members in my Subclass 500 Visa application? 
              </h2>
              {activeIndex === 3 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 3 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                Yes, immediate family members, such as a spouse or children, can be included in the application. They will also be eligible for certain work and study rights.                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 4 */}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(4)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              4. What are the English language requirements for a Subclass 500 Visa? 
              </h2>
              {activeIndex === 4 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 4 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                English proficiency requirements depend on the course and institution. Generally, an IELTS score or equivalent is necessary unless exempted based on country of origin or previous education in English.                </p>
              </div>
            )}
          </div>
          {/* Accordion Section 5*/}
          <div className="accordion mt-2">
            {/* Accordion header */}
            <div
              className="accordion-header flex justify-between items-center p-4 cursor-pointer border-b"
              onClick={() => toggleAccordion(5)}
            >
              <h2 className="md:text-xl text-lg text-blue font-medium hellix-font">
              5. How many hours can I work on a Subclass 500 Visa? 
              </h2>
              {activeIndex === 5 ? <FaTimes /> : <FaPlus />}
            </div>

            {/* Accordion content */}
            {activeIndex === 5 && (
              <div className="accordion-content p-4">
                <p className="text-[#667d87] md:text-xl text-lg font-normal hellix-font">
                You are allowed to work up to 48 hours per fortnight while your course is in session and unlimited hours during breaks.                </p>
              </div>
            )}
          </div>

        </div>
      </div>

      <Contact />
    </div>
  );
};

export default StudentVisa;
